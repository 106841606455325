import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "./reducers";
import { rtkQuery } from './api/curriculum/rtk-setup';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

const enhancers = []
// const middleware = [thunk, routerMiddleware(history),logger]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}


const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, rtkQuery.middleware),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQuery.middleware), // removed unneccessary logs from redux-logger
  // enhancers: composedEnhancers
})
setupListeners(store.dispatch);
export default store;