import axios from "axios";
import * as config from "@/config";
import Pusher from "pusher-js";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseApp } from "..";
import { updateDeviceUpsertToken } from "./actionDispatcher";

export const getLoggedInUserToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  if (token && token.accessToken) return token;
};

export const isUserAuthenticated = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token?.accessToken}`,
    accountId: `${token?.account?.id}`,
    Accept: "application/json",
  };

  return token?.accessToken ? true : false;
};
export const isNotUser = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRoleLevel = token?.user?.user_role?.level;
  return userRoleLevel < 4;
};

export const isSuperAdmin = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "admin";
};

export const isAdministrator = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "manager";
};

export const isReviewer = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "curriculum-reviewer";
};
export const isAuthor = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "curriculum-author";
};

export const isDistributor = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "distributor";
};

export const isManager = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "account-manager";
};

export const isFormAdmin = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "form-administrator";
};

export const isServiceTechnician = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.account?.is_service_account;
  //&& token?.user?.user_role?.slug === 'manager' ;
  return userRole;
};

export const isUser = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userRole = token?.user?.user_role?.slug;
  return userRole === "user";
};

export const authHeader = () => {
  let token = JSON.parse(localStorage.getItem("token"));
  return token && token.accessToken
    ? { Authorization: "Bearer " + token.accessToken }
    : {};
};

export const getAccountId = () => {
  let data = JSON.parse(localStorage.getItem("token"));
  return data?.account?.id;
};

export const getTimeZoneId = () => {
  let data = JSON.parse(localStorage.getItem("token"));
  return (
    parseInt(data?.account?.timezone_id) || parseInt(data?.user?.timezone?.id)
  );
};

export const setUserToken = (data) => {
  data.account = data?.account || data.user.account;
  localStorage.setItem("token", JSON.stringify(data));

  if (data && data.accessToken)
    // axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`
    axios.defaults.headers.common = {
      Authorization: `Bearer ${data?.accessToken}`,
      accountId: `${data?.account?.id}`,
      Accept: "application/json",
    };
};

export const pusher = new Pusher(config.PUSHER_APP_KEY, {
  cluster: config.PUSHER_APP_CLUSTER || "mt1",
  forceTLS: config.PUSHER_USE_TLS || false,
});
export const activityChannel = pusher.subscribe("activity");

export const shortenString = (data) => {
  if (data?.length > 0) {
    let newText = data;
    if (data.length > 50) {
      return newText.slice(0, 50) + "...";
    } else {
      return data;
    }
  }
};

export async function requestPermission() {
  if ("serviceWorker" in navigator) {
    const messaging = getMessaging(firebaseApp);
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey: config.VAPID_KEY,
        });
        let data = {
          type: "web",
          token: token,
          status: "active",
        };
        updateDeviceUpsertToken(data);
      } catch (e) {}
    }
  }
}
