import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdDelete,
  MdOutlineDragHandle,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { BsDiagram2 } from "react-icons/bs";

const FormFieldListItem = ({
  savedData,
  handleChildFormModal,
  handleFormFieldUpdate,
  handleFormFieldDelete,
  handleDragEndDataForSavedData,
}) => {
  const [optionsData, setOptionsData] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);

  useEffect(() => {
    setOptionsData(savedData);
  }, [savedData]);

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    const currentIndex = optionsData.findIndex(
      (item) => item.id === draggingItem.id
    );
    const targetIndex = optionsData.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      const newItems = [...optionsData];
      const draggedItem = newItems[currentIndex];
      newItems.splice(currentIndex, 1);
      newItems.splice(targetIndex, 0, draggedItem);

      setOptionsData(newItems);
      handleDragEndDataForSavedData(newItems);
    }
  };

  return (
    <tbody>
      {optionsData.map((item, index) => (
        <FormFields
          key={item.id}
          item={item}
          index={index}
          optionsData={optionsData}
          handleDragStart={handleDragStart}
          handleDrop={handleDrop}
          handleFormFieldUpdate={handleFormFieldUpdate}
          handleFormFieldDelete={handleFormFieldDelete}
          handleChildFormModal={handleChildFormModal}
          draggingItem={draggingItem}
          handleDragEnd={handleDragEnd}
          handleDragOver={handleDragOver}
          hierarchicalId={index + 1}
        />
      ))}
    </tbody>
  );
};

const FormFields = ({
  item,
  index,
  handleDragStart,
  handleDrop,
  handleChildFormModal,
  draggingItem,
  handleDragEnd,
  handleDragOver,
  optionsData,
  handleFormFieldUpdate,
  handleFormFieldDelete,
  hierarchicalId,
}) => {
  const sortChildData = item?.elements?.sort?.(
    (itemA, itemB) => +itemA?.order - +itemB?.order
  );

  const isHTML = /<\/?[a-z][\s\S]*>/i.test(item?.labelName);

  return (
    <>
      <tr
        key={item.id}
        className={
          !item.parent_id ? `item ${item === draggingItem ? "drag" : ""}` : ""
        }
        draggable={!item.parent_id ? "true" : "false"}
        onDragStart={
          !item.parent_id ? (e) => handleDragStart(e, item) : undefined
        }
        onDragEnd={!item.parent_id ? handleDragEnd : undefined}
        onDragOver={!item.parent_id ? handleDragOver : undefined}
        onDrop={!item.parent_id ? (e) => handleDrop(e, item) : undefined}
        style={{
          cursor: !item.parent_id ? "grab" : "default",
          borderBottom: "1px solid #dee2e6",
        }}
      >
        <td>
          {!item?.parent_id ? (
            <MdOutlineDragHandle
              size={16}
              color="grey"
              style={{ cursor: "move" }}
            />
          ) : (
            <BsDiagram2 size={16} color="grey" />
          )}
        </td>
        <td className="text-secondary">{hierarchicalId}</td>
        {isHTML ? (
          <>
            <td className="text-secondary text-break text-capitalize">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div>${item?.labelName?.replace(
                    /(<a\s+(?:[^>]*?\s+)?href="([^"]*)")/g,
                    '<a target="_blank" $1'
                  )}</div>`,
                }}
                className="information-page-container"
              />
            </td>
          </>
        ) : (
          <td className="text-secondary text-break text-capitalize">
            {item?.labelName}
          </td>
        )}
        <td className="text-secondary text-break text-capitalize">
          {item?.formElementType?.replace(/_/g, " ")}
        </td>
        <td
          className={
            item?.statusField === "active"
              ? "text-success"
              : item?.statusField === "inactive"
              ? "text-danger"
              : "text-secondary"
          }
        >
          {item?.statusField?.charAt(0)?.toUpperCase() +
            item?.statusField?.slice(1)}
        </td>
        <td className="text-secondary d-flex justify-content-end gap-2">
          {item?.formElementType &&
            ["select", "radio", "checkbox"].includes(item?.formElementType) && (
              <p className="cursor-pointer">
                <MdAdd
                  className="text-danger"
                  onClick={() => handleChildFormModal(item)}
                  id={`addForm.button.actionAdd.${hierarchicalId}`}
                />
              </p>
            )}
          <p className="cursor-pointer">
            <MdOutlineModeEditOutline
              className="text-primary"
              onClick={() => {
                handleFormFieldUpdate(item);
              }}
              id={`addForm.button.actionEdit.${hierarchicalId}`}
            />
          </p>
          <p className="cursor-pointer">
            <MdDelete
              className="text-danger"
              onClick={() => {
                handleFormFieldDelete(item);
              }}
              id={`addForm.button.actionDelete.${hierarchicalId}`}
            />
          </p>
        </td>
      </tr>
      {item?.elements?.length > 0 &&
        sortChildData.map((mapItem, mapIndex) => (
          <FormFields
            key={mapItem.id}
            item={mapItem}
            index={`${index}.${mapIndex + 1}`}
            optionsData={optionsData}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            handleFormFieldUpdate={handleFormFieldUpdate}
            handleFormFieldDelete={handleFormFieldDelete}
            handleChildFormModal={handleChildFormModal}
            draggingItem={draggingItem}
            handleDragEnd={handleDragEnd}
            handleDragOver={handleDragOver}
            hierarchicalId={`${hierarchicalId}.${mapIndex + 1}`}
          />
        ))}
    </>
  );
};

export default FormFieldListItem;
