import { getLocationTypes } from "@/services";
import { createSlice } from "@reduxjs/toolkit";
import {
  getAccessoryTypeList,
  getReportDetail,
  getReportDetailList,
  getReportEquipment,
  getReportList,
  getReportLocations,
  getReportUsersList,
} from "../services/report.service";

const initialState = {
  reportList: [],
  equipmentList: [],
  reportDetail: [],
  reportDetailList: [],
  usersList: [],
  locationList: [],
  locationTypes: [],
  accessoryTypes: [],
};

export const report = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearReportList: (state) => {
      state.reportDetailList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportEquipment.fulfilled, (state, action) => {
        state.equipmentList = action.payload?.data;
      })
      .addCase(getReportList.fulfilled, (state, action) => {
        state.reportList = action.payload?.data;
      })
      .addCase(getReportDetail.fulfilled, (state, action) => {
        state.reportDetail = action.payload?.data;
      })
      .addCase(getReportDetailList.fulfilled, (state, action) => {
        state.reportDetailList = action.payload?.data;
      })
      .addCase(getReportUsersList.fulfilled, (state, action) => {
        state.usersList = action.payload?.data;
      })
      .addCase(getReportLocations.fulfilled, (state, action) => {
        state.locationList = action.payload?.data;
      })
      .addCase(getLocationTypes.fulfilled, (state, action) => {
        state.locationTypes = action.payload?.data;
      })
      .addCase(getAccessoryTypeList.fulfilled, (state, action) => {
        state.accessoryTypes = action.payload?.data;
      });
  },
});

export const { clearReportList } = report.actions;
export default report.reducer;
