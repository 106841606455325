import editIcon from "@images/edit.png";
import infoIcon from "@images/info.png";
import trashIcon from "@images/trash.png";
import viewIcon from "@images/eye00f.png";
import exportIcon from "@images/export.png";
import downloadIcon from "@images/download00f.png";
import logInspection from "@images/logInspection.png";
import logActivity from "@images/logActivity.png";
import Accept from "@images/check.png";
import Cross from "@images/cross.png";
import deletes from "@images/delete.png";
import schedule from "@images/schedule.png";
import duplicateIcon from "@images/duplicate.png";
import replaceIcon from "@images/replace.png";
import { Image, Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import { BsThreeDotsVertical } from "react-icons/bs";

const ToolTip = (props) => {
  let {
    show,
    setShow,
    options = [],
    id,
    index,
    // onHide
  } = props;

  const getImage = (name) => {
    if (name === "Edit") return editIcon;
    if (name === "Duplicate") return duplicateIcon;
    if (name === "View" || name === "Responses") return viewIcon;
    if (name === "Detail") return infoIcon;
    if (name === "Remove") return trashIcon;
    if (name === "Export") return exportIcon;
    if (name === "Download") return downloadIcon;
    if (name === "Log Activity") return logActivity;
    if (name === "Approve") return Accept;
    if (name === "Remove ") return deletes;
    if (name === "Reject") return Cross;
    if (name === "Replace") return replaceIcon;
    if (name === "Log Inspection" || name === "Log Service")
      return logInspection;
    if (name === "Edit Schedule" || "Add Schedule") return schedule;
  };

  return (
    <OverlayTrigger
      trigger="click"
      as={Overlay}
      key={"placement"}
      placement={"bottom"}
      show={show}
      // onHide={onHide}
      rootClose
      overlay={
        <Popover id={`popover-positioned-bottom`}>
          <Popover.Body className="p-2 align-items-start">
            {options.map((option, ind) => {
              return (
                <a
                  className="border-0 Bg-fff linkText"
                  href={option.fileUrl}
                  onClick={option.onClick}
                  role="button"
                  download
                  target="_blank"
                  rel="noreferrer"
                  id={`${id}.${_.camelCase(option.name)}`}
                >
                  {ind ? <hr className="mt-1 mb-1" /> : null}
                  {getImage(option.name) && (
                    <Image
                      src={getImage(option.name)}
                      alt="Icon"
                      className="editIcon"
                    />
                  )}
                  <strong className="C-link"> {option.name}</strong>
                </a>
              );
            })}
          </Popover.Body>
        </Popover>
      }
    >
      <button
        id={
          id
            ? `${id}${index !== null || index !== undefined ? `.${index}` : ""}`
            : ""
        }
        onClick={setShow}
        disabled={options?.length > 0 ? false : true}
        className="edit-btn C-primary rounded-circle toolTip center"
      >
        <BsThreeDotsVertical />
      </button>
    </OverlayTrigger>
  );
};

export default ToolTip;
