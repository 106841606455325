import csv from "@images/csv.png";
import pdf from "@images/pdf.png";
import xls from "@images/xls.png";
import xlsx from "@images/xlsx.png";

export const statusData = [
  { name: "Active", status: "Active" },
  { name: "Inactive", status: "Inactive" },
];

export const equipmentStatus = [
  { key: "active", name: "Active" },
  { key: "inactive", name: "Inactive" },
  { key: "trial", name: "Trial" },
  { key: "disposed", name: "Disposed" },
];

export const medicationStatus = [
  { key: "active", name: "Active" },
  { key: "inactive", name: "Inactive" },
  { key: "trial", name: "Trial" },
];

export const minimumValueData = [
  { name: "0", status: 0 },
  { name: "1", status: 1 },
];

export const maximumValueData = [
  { name: "5", status: 5 },
  { name: "10", status: 10 },
];

export const pdfFormatType = [
  { id: 1, label: "PDF-Landscape" },
  { id: 2, label: "PDF-Portrait" },
];

export const formTypeData = [
  { name: "Input", key: "input" },
  { name: "Select", key: "select" },
  { name: "Radio", key: "radio" },
  { name: "Checkbox", key: "checkbox" },
  { name: "Date", key: "date" },
  { name: "Time", key: "time" },
  { name: "File", key: "file" },
  { name: "Textarea", key: "textarea" },
  { name: "Multiple Choice Grid", key: "multiple_choice_grid" },
  { name: "Linear", key: "linear" },
  { name: "Scale", key: "scale" },
  { name: "Information", key: "information" },
];

export const privacyLevelData = [
  { id: true, name: "Shared" },
  { id: false, name: "Private" },
];

export const DuplicateCurrSelectOptions = [
  {
    id: 1,
    name: "Section",
    value: "section",
  },
  {
    id: 2,
    name: "Unit",
    value: "unit",
  },
  {
    id: 3,
    name: "Lesson",
    value: "lesson",
  },
  {
    id: 4,
    name: "Component",
    value: "component",
  },
  {
    id: 5,
    name: "Paragraph",
    value: "paragraph",
  },
];

export const TOKEN_EXPIRE_TIME = 420;

// Report
export const reportData = [
  { name: "Training", type: "course" },
  { name: "Credential", type: "credential" },
  { name: "Order", type: "order" },
  { name: "Inventory", type: "inventory" },
  { name: "Equipment", type: "equipment" },
  { name: "Medication", type: "medication" },
];

export const reportStaticData = [
  { name: "Location", type: "location" },
  { name: "People", type: "user" },
];

export const reportStaticSubData = {
  Equipment: [
    { name: "Accessory", type: "accessory" },
    { name: "Activity", type: "activity" },
    { name: "Document", type: "document" },
    { name: "Inspection", type: "inspection" },
  ],
  Medication: [
    { name: "Activity", type: "activity" },
    { name: "Document", type: "document" },
    { name: "Inspection", type: "inspection" },
  ],
  Location: [{ name: "Document", type: "document" }],
};

export const reportSortData = [
  { key: "name", name: "Name" },
  { key: "created_at", name: "Created At" },
  { key: "type", name: "Report Type" },
];

export const reportFilterReports = [
  { name: "Accessory", type: "accessory" },
  { name: "Activity", type: "activity" },
  { name: "Document", type: "document" },
  { name: "Inspection", type: "inspection" },
];

export const exportFileTypes = [
  { name: "CSV", format: "csv", icon: csv },
  { name: "PDF", format: "pdf", icon: pdf },
  { name: "XLS", format: "xls", icon: xls },
  { name: "XLSX", format: "xlsx", icon: xlsx },
];
