import { useEffect } from "react";
import moment from "moment";
import { TOKEN_EXPIRE_TIME } from "./mock";
import { getLoggedInUserToken } from "./auth";
import { refreshTokenActionDispatcher } from "./actionDispatcher";

const useGlobalEvents = () => {
  useEffect(() => {
    window.addEventListener("keydown", handleEvent);
    window.addEventListener("click", handleEvent);
    return () => {
      window.removeEventListener("keydown", handleEvent);
      window.removeEventListener("click", handleEvent);
    };
  }, []);

  const handleEvent = (event) => {
    let eventDetails;
    switch (event.type) {
      case "keydown":
        eventDetails = {
          type: "keydown",
          key: event.key,
          keyCode: event.keyCode,
        };
        break;

      case "click":
        eventDetails = {
          type: "click",
          x: event.clientX,
          y: event.clientY,
        };
        break;

      default:
        break;
    }
    if (eventDetails) {
      handleRefreshToke();
    }
  };

  let isRefreshingToken = false;

  const handleRefreshToke = async () => {
    const { expiresAt } = getLoggedInUserToken() || {};
    const expirationDate = moment.utc(expiresAt, "YYYY-MM-DD HH:mm:ss");
    const currentDate = moment.utc();
    const timeDifference = expirationDate.diff(currentDate, "minutes");

    if (
      timeDifference > 0 &&
      timeDifference <= TOKEN_EXPIRE_TIME &&
      !isRefreshingToken
    ) {
      isRefreshingToken = true;
      await refreshTokenActionDispatcher();
      setTimeout(() => {
        isRefreshingToken = false;
      }, 5000);
    }
  };
};

export { useGlobalEvents };
