import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as config from "../config";
import { getQueryParams, setUserToken } from "../helpers";
import {
  apiRequest,
  apiRequestBodyParams,
  apiRequestParams,
  refreshTokenAPI,
} from "./equipment.services";
import { toast } from "react-toastify";

export const register = apiRequest("post", "users/create", "REGISTER_USER");

export const login = apiRequestBodyParams("post", "users/login", "LOGIN");
export const refreshToken = refreshTokenAPI("post", "refresh", "REFRESH_TOKEN");

export const logoutApiRequest = (
  method,
  end_point,
  action_type = "CALL_API"
) => {
  return createAsyncThunk(action_type, async () => {
    try {
      const url = `${config.API_URL + end_point}`;
      const response = await axios[method](url);
      return response.data;
    } catch (err) {
      throw err?.response.data;
    }
  });
};

export const welnetToken = createAsyncThunk("WELNET_TOKEN", async (params) => {
  const { data } = params;
  try {
    const response = await axios.get(
      `${config.API_URL}welnet/token?${getQueryParams(data)}`
    );
    return response.data;
  } catch (err) {
    toast.error(err?.message);
  }
});
export const welnetLogin = createAsyncThunk("WELNET_LOGIN", async (params) => {
  const { data } = params;
  try {
    const response = await axios.get(
      `${config.API_URL}welnet/login?${getQueryParams(data)}`
    );
    return response.data;
  } catch (err) {
    toast.error(err?.message);
  }
});

// export const welnetLogin = apiRequestBodyParams('get', 'oauth/login', 'WELNET_LOGIN');

export const socialLogin = (data) => {
  setUserToken(data);
};

export const logout = () => {
  localStorage.clear();
};

export const forgotPassword = apiRequest(
  "post",
  "users/forgot-password",
  "FORGOT_PASSWORD"
);
export const resetPassword = apiRequest(
  "post",
  "users/reset-password",
  "RESET_PASSWORD"
);
export const changePassword = apiRequestBodyParams(
  "post",
  "users/change-password",
  "CHANGE_PASSWORD"
);
export const updateProfile = apiRequestBodyParams(
  "patch",
  "users",
  "UPDATE_PROFILE"
);
export const getUserProfile = apiRequest("get", "users/profile", "GET_PROFILE");
export const logoutApi = logoutApiRequest("get", "users/logout", "LOGOUT");
export const uploadImage = createAsyncThunk("UPLOAD_IMAGE", async (data) => {
  const { filess, getPercent } = data;
  const response = await axios.post(`${config.API_URL}files/image`, filess, {
    headers: { "Content-Type": "application/octet-stream" },
    onUploadProgress: (e) => {
      var uploadPercent = Math.round((e.loaded * 100) / e.total);
      getPercent(uploadPercent);
    },
  });
  return response.data;
});

export const uploadMediaImage = createAsyncThunk(
  "UPLOAD_MEDIA_IMAGE",
  async (data) => {
    try {
      const { filess } = data;
      const response = await axios.post(
        `${config.API_URL}files/image`,
        filess,
        {
          headers: { "Content-Type": "application/octet-stream" },
        }
      );
      return response.data.data;
    } catch (err) {
      throw err.response?.statusText;
    }
  }
);

export const uploadFile = createAsyncThunk("UPLOAD_FILE", async (data) => {
  try {
    const response = await axios.post(`${config.API_URL}files/file`, data, {
      headers: { "Content-Type": "application/octet-stream" },
    });
    return response.data.data;
  } catch (err) {
    throw err.response?.statusText;
  }
});

export const uploadPublicFile = createAsyncThunk(
  "UPLOAD_PUBLIC_FILE",
  async (data) => {
    try {
      const response = await axios.post(
        `${config.API_URL}files/public/file`,
        data,
        { headers: { "Content-Type": "application/octet-stream" } }
      );
      return response.data.data;
    } catch (err) {
      throw err.response?.statusText;
    }
  }
);

export const getOrganizationList = apiRequest(
  "get",
  "accounts/list",
  "GET_ORGANIZATION_LIST"
);
export const getDistributorList = apiRequest(
  "get",
  "distributors/list",
  "GET_DISTRIBUTOR_LIST"
);
export const getLicenseDetail = apiRequest(
  "get",
  "licenses/detail",
  "GET_LICENSE_DETAIL"
);
export const getLicenseHistory = apiRequest(
  "get",
  "licenses/history",
  "GET_LICENSE_HISTORY"
);

export const getLicensePermissions = apiRequest(
  "get",
  "licenses/check-permission",
  "GET_LICENSE_PERMISSION"
);

export const createEnquiries = apiRequestBodyParams(
  "post",
  "enquiries/create",
  "CREATE_ENQUIRIES"
);
export const getTimeZoneList = apiRequest(
  "get",
  "time-zones",
  "GET_TIME_ZONE_LIST"
);
export const updateDeviceToken = apiRequest(
  "post",
  "users/upsert-token",
  "UPDATE_DEVICE_TOKEN"
);
export const getEmailVerify = apiRequest(
  "get",
  "users/resend-verification-mail",
  "GET_EMAIL_VERIFY"
);
