import React from "react";
import { isUserAuthenticated, usePageTitle } from "@/helpers";
import { Link, useLocation } from "react-router-dom";
import logo from "@images/logo.png";
import "../index.css";

const FormSubmit = () => {
  const location = useLocation();
  usePageTitle("School Health Form Submit");
  window.scrollTo({ top: 0 });

  const submitMessage = location?.state?.submit_message || "";
  const isAuthenticated = isUserAuthenticated();

  return (
    <>
      {!isAuthenticated && (
        <div className="d-flex justify-content-start col-12 Bg-fff">
          <div className="p-3">
            <Link to="/">
              <img alt="logo" src={logo} className="w-50" />
            </Link>
          </div>
        </div>
      )}
      {submitMessage.length > 0 ? (
        <div
          className="form-submit-container"
          style={{
            marginTop: isAuthenticated ? "6pc" : "2pc",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: submitMessage.replace(
                /(<a\s+(?:[^>]*?\s+)?href="([^"]*)")/g,
                '<a target="_blank" $1'
              ),
            }}
            className="information-page-content"
          />
        </div>
      ) : (
        <div
          className="submitMessageContainer"
          style={{ minHeight: isAuthenticated ? "100vh" : "80vh" }}
        >
          <h3>Thank you for submitting your form</h3>
        </div>
      )}
    </>
  );
};

export default FormSubmit;
