import { isUserAuthenticated, setUserToken } from "@/helpers";
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  getLicensePermissions,
  getDistributorList,
  getOrganizationList,
  getTimeZoneList,
  getUserProfile,
  login,
  register,
  uploadFile,
  uploadImage,
  getEmailVerify,
  welnetLogin,
  welnetToken,
  refreshToken,
} from "@/services/auth.service";

const token = JSON.parse(localStorage.getItem("token"));
const initialState =
  token && token.user
    ? {
        isLoggedIn: true,
        user: token.user,
        image: null,
        organizationList: null,
        distributorList: null,
        licensePermissions: {},
        timeZoneList: null,
        loading: false,
        webApp: localStorage.getItem("web")
          ? localStorage.getItem("web")
          : "schoolhealth",
        dashboardLoader: false,
        isRefreshTokenData: {},
      }
    : { isLoggedIn: false, user: null, loading: false };

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.user = null;
    },
    changeWebApp: (state, action) => {
      // localStorage.setItem('web', action.payload );
      // state.webApp = action.payload
    },
    expiredTokenModal: (state) => {
      localStorage.setItem("expiredTokenModal", true);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        setUserToken(action.payload?.data);
        state.isLoggedIn = true;
        state.user = action?.payload?.data?.user;
      })
      .addCase(login.rejected, (state) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isRefreshTokenData = action?.payload;
        if (action?.payload?.data) {
          let tokenData = JSON.parse(localStorage.getItem("token"));
          const { accessToken, expiresAt, refreshToken } =
            action?.payload?.data;
          let data = {
            ...tokenData,
            accessToken,
            expiresAt,
            refreshToken,
          };
          setUserToken(data);
        }
      })
      .addCase(refreshToken.rejected, (state) => {
        state.isRefreshTokenData = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        let token1 = JSON.parse(localStorage.getItem("token")) || {};
        token1.user = action?.payload?.data;
        setUserToken(token1);
        state.isLoggedIn = true;
        state.user = action.payload?.data;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.image = action.payload?.data;
      })
      .addCase(getOrganizationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(welnetToken.pending, (state) => {
        state.dashboardLoader = true;
      })
      .addCase(welnetLogin.pending, (state) => {
        state.dashboardLoader = true;
      })
      .addCase(welnetToken.fulfilled, (state) => {
        state.dashboardLoader = false;
      })
      .addCase(welnetLogin.fulfilled, (state) => {
        state.dashboardLoader = false;
      })
      .addCase(getOrganizationList.fulfilled, (state, action) => {
        let tokenOrg = JSON.parse(localStorage.getItem("token"));
        tokenOrg.account =
          tokenOrg?.account ||
          (action?.payload?.data && action?.payload?.data[0]);
        localStorage.setItem("token", JSON.stringify(tokenOrg));
        isUserAuthenticated();
        state.organizationList = action.payload?.data || [];
        state.loading = false;
      })
      .addCase(getDistributorList.fulfilled, (state, action) => {
        let tokenOrg = JSON.parse(localStorage.getItem("token"));
        tokenOrg.account =
          tokenOrg?.account ||
          (action?.payload?.data && action?.payload?.data[0]);
        localStorage.setItem("token", JSON.stringify(tokenOrg));
        isUserAuthenticated();
        state.distributorList = action.payload?.data || [];
        state.loading = false;
      })
      .addCase(getTimeZoneList.fulfilled, (state, action) => {
        let data = action.payload?.data;
        data = _.map(data, (item) =>
          _.mapKeys(item, (v, keyName) => {
            if (keyName === "text") return "name";
            else return keyName;
          })
        );
        state.timeZoneList = data || [];
      })
      .addCase(getLicensePermissions.fulfilled, (state, action) => {
        state.licensePermissions = action.payload?.data;
      });
  },
});

export const { logout, changeWebApp, expiredTokenModal } = auth.actions;
export default auth.reducer;
