import { FormControl, Form } from 'react-bootstrap';

const TextInput = (props) => {
  let { /*placeholder, name, onChange, defaultValue, disabled, style, value, type, autoFocus,*/
    lable, as, isTextArea, id, classes, disabled, validationText, validationTextClass, lableClass, EyeComponent, noShadow, maxlength, isCurriculum, readOnly,onKeyDown } = props
  return (
    <Form.Group autoComplete="off"
      controlId={id || "formGridLocation"} as={as}>
      <div className='d-flex justify-content-between'>
        <Form.Label className={`bold lable C-dark mt-1 ${lableClass}`} style={{ display: lable ? '' : 'none' }}>{lable}</Form.Label>
        {
          isCurriculum ?
            <Form.Text className={`text-danger align-self-center ${validationTextClass}`} style={{ fontSize: '10px' }}>{validationText}</Form.Text>
            : ''
        }
      </div>
      <FormControl
        //  name={name} type={type} style={style} value={value} disabled={disabled} placeholder={placeholder} defaultValue={defaultValue} autoFocus={autoFocus} onChange={onChange} 
        {...props}
        disabled={disabled}
        as={isTextArea}
        className={`${noShadow ? '' : 'Shadow'} dropDown ${classes}`}
        variant='light'
        aria-autocomplete='off'
        autoComplete={'off'}
        maxLength={maxlength}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
      />
      {EyeComponent}
      {
        !isCurriculum ?
          <Form.Text id={id} className={`text-danger ${validationTextClass}`} style={{ fontSize: '12px' }}>{validationText}</Form.Text>
          : ''
      }
    </Form.Group>
  )
}
export default TextInput;