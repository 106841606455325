import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BiFileBlank } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import loadIcon from "@images/load.gif";
import { getCarriculumSearchResults } from "@/services";
import { BiSearch } from "react-icons/bi";
import "./search.css";
import { searchedCurriculumItem, setToInitial } from "@/reducers/curriculum";
import Select from "react-select";
import { toast } from "react-toastify";

const CurriculumSearch = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reducerData = useSelector((state) => {
    return {
      searchedCurriculum: state.curriculum.searchedCurriculumItem,
    };
  });
  const { searchedCurriculum } = reducerData;
  const [state, setState] = useState({
    limit: 20,
    offset: 0,
    totalResults: 0,
    category: [{ value: "all", label: "All" }],
    categoryData: [],
  });
  let isCategories = [];
  let {
    isShowSearchedWindow = false,
    isProcessing = false,
    search,
    results,
    totalResults,
    offset,
    category,
    categoryData,
  } = state;
  const inputRef = useRef(null);
  const options = [
    { value: "all", label: "All" },
    { value: "curriculum", label: "Curriculum" },
    { value: "version", label: "Version" },
    { value: "section", label: "Section" },
    { value: "unit", label: "Unit" },
    { value: "lesson", label: "Lesson" },
    { value: "component", label: "Component" },
    { value: "paragraph", label: "Paragraph" },
  ];
  const [getting, setGetting] = useState(false);
  const { loading = false, isGettingMore = false, isGetAll = false } = getting;

  useEffect(() => {
    if (!searchedCurriculum?.id) {
      setState({ ...state, search: "" });
    }
  }, [searchedCurriculum]);

  useEffect(() => {
    if (isShowSearchedWindow) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
      setState((prev) => ({
        ...prev,
        category: [{ value: "all", label: "All" }],
      }));
    };
  }, [isShowSearchedWindow]);

  const handleClickedData = (item) => {
    dispatch(searchedCurriculumItem(item));
    dispatch(setToInitial(true));
    setState({ ...state, isShowSearchedWindow: !isShowSearchedWindow });
    let id;
    switch (item?.type) {
      case "curriculum": {
        id = item?.id;
        break;
      }
      case "component": {
        id = item?.lesson?.unit?.section?.version?.curriculum?.id;
        break;
      }
      case "paragraph": {
        id =
          item?.component_paragraphs?.[0]?.component?.lesson?.unit?.section
            ?.version?.curriculum?.id;
        break;
      }
      case "gradeLevel": {
        id = item?.version?.curriculum?.id;
        break;
      }
      case "section": {
        id = item?.version?.curriculum?.id;
        break;
      }
      case "lesson": {
        id = item?.unit?.section?.version?.curriculum?.id;
        break;
      }
      case "version": {
        id = item?.curriculum?.id;
        break;
      }
      case "unit": {
        id = item?.section?.version?.curriculum?.id;
        break;
      }
      default: {
        id = null;
        break;
      }
    }

    if (id) {
      setState({ ...state, isShowSearchedWindow: !isShowSearchedWindow });
      navigate(`/curriculum/${id}`);
    } else {
      toast.error("Item Not Found!");
    }
  };

  const SearchedItem = ({ item, index }) => {
    return (
      <div
        className="notificationItem"
        role="button"
        onClick={() => handleClickedData(item)}
        id={`header.curriculum.search.resultItem.${index}`}
      >
        <div className="w-100 align-items-center text-decoration-none">
          <div
            className={`notificationMessage curriculumSearchItem justify-content-between mx-2 ${
              isProcessing ? "processing" : ""
            }`}
          >
            <p
              className="align-self-center searchedText"
              style={{ wordBreak: "break-all" }}
            >
              {item.type}
            </p>
            <p className="searchedText me-2" style={{ width: "70%" }}>
              {item.name}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const onScroll = (e) => {
    var element = e.target;
    if (element.scrollHeight < element.scrollTop + element.clientHeight + 3) {
      !isGetAll &&
        !isGettingMore &&
        totalResults !== results?.length &&
        loadMoreSearchedData(isCategories);
    }
  };

  const loadMoreSearchedData = () => {
    setGetting({ isGettingMore: true, isGetAll });
    let objectParams = { search: search };
    const valuesArray = category.map((obj) => obj.value);
    let payload = {
      ...objectParams,
      limit: 20,
      offset: _.size(results),
      ...(params?.id && { curriculum_id: params?.id }),
      keys: valuesArray?.length ? valuesArray : ["all"],
    };

    if (search?.length) {
      dispatch(getCarriculumSearchResults(payload))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setState((prev) => ({
              ...prev,
              results: [...results, ...res?.data],
              totalResults: res?.total,
            }));
            setGetting({ ...getting, isGettingMore: false });
          } else setGetting({ ...getting, isGettingMore: false });
        });
    }
  };

  const handleApiCall = (isCategories) => {
    setGetting({ ...getting, isGettingMore: true, loading: true });
    const searchParams = search;
    let objectParams = { search: search };

    if (searchParams) {
      let payload = {
        ...objectParams,
        limit: 20,
        offset: 0,
        ...(params?.id && { curriculum_id: params?.id }),
        keys: isCategories?.length ? isCategories : ["all"],
      };
      if (search?.length) {
        dispatch(getCarriculumSearchResults(payload))
          .unwrap()
          .then((res) => {
            if (res) {
              setState((prev) => ({
                ...prev,
                searchKey: objectParams.search,
                results: res?.data,
                totalResults: res?.total,
              }));
              setGetting({ ...getting, isGettingMore: false, loading: false });
            } else {
              setGetting({ ...getting, isGettingMore: false, loading: false });
            }
          });
      } else {
        setGetting({ ...getting, isGettingMore: false, loading: false });
      }
    }
  };

  const handleSubmit = () => {
    setState({ ...state, results: [], isShowSearchedWindow: true });
  };

  useEffect(() => {
    if (category?.length) {
      category?.forEach((i) => {
        isCategories.push(i.value.toLowerCase());
      });
      handleApiCall(isCategories);
    }
  }, [category]);

  const handleSelect = (values, target) => {
    let newCategories = [];
    const data = values?.some?.((itemData) => itemData?.value == "all");
    if (
      (data && category?.length > 1) ||
      (data && values?.length == 0) ||
      (values.length > 1 && values[1].value === "all") ||
      !values.length
    ) {
      newCategories = [options[0]];
    } else if ((data && category?.length == 1) || (data && values.length > 1)) {
      newCategories = values.filter((item) => item.value !== "all");
    } else {
      newCategories = values;
    }
    setState((prev) => ({ ...prev, category: newCategories }));
  };

  return (
    <>
      <Col
        className="d-flex align-items-center justify-content-start justify-content-md-center h-100"
        onClick={() => setState({ ...state, isShowSearchedWindow: false })}
      >
        <div
          className="rounded ps-2 center"
          style={{ height: "43px", width: "80%" }}
        >
          <div
            className="h-100 center ps-2"
            style={{
              color: "#cdcdcd",
              background: "#f5f5f5",
              borderRadius: "6px 0px 0px 6px",
            }}
          >
            <BiSearch
              id="header.curriculum.button.search"
              size={25}
              onClick={() => inputRef.current.focus()}
            />
          </div>
          <input
            id="header.curriculum.input.search"
            type="text"
            placeholder="Search"
            maxLength={50}
            ref={inputRef}
            value={search}
            name="search"
            autocomplete="off"
            className="border-0 m-0 p-0 ps-2 h-100 searchInput"
            style={{ fontSize: "20px" }}
            onChange={(e) => setState({ ...state, search: e.target.value })}
            onKeyUp={(event) => {
              if (event.keyCode === 13 && search) {
                handleSubmit();
              }
            }}
          />
        </div>
        {isShowSearchedWindow && (
          <div className="notificationWindowBackDrop">
            <div
              className="Shadow curriculumSearchWindow"
              style={{
                right: window.location.pathname?.includes("curriculum/")
                  ? "40vw"
                  : "30vw",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="ms-4 mt-2 ">
                <div className="me-5">
                  <p>Filter results by :-</p>
                  <Select
                    isMulti
                    isClearable
                    value={category}
                    name="category"
                    options={options}
                    onChange={handleSelect}
                    placeholder="Select Category"
                    closeMenuOnSelect={true}
                    classNamePrefix="F-12 a"
                    isDisabled={loading}
                    id={"header.curriculum.search.button.filter"}
                    inputId={"header.curriculum.search.input.filter"}
                  />
                </div>
                <div>
                  <Col className="C-curriculum">Search Results</Col>
                  <Col className="sort">
                    Results {offset + (totalResults ? 1 : 0)}-
                    {_.size(results) + offset} of {totalResults}
                  </Col>
                </div>
              </div>
              {loading ? (
                <div className="center">
                  <img
                    src={loadIcon}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "fill",
                    }}
                  />
                </div>
              ) : results?.length ? (
                <div
                  className={`Content p-1 pb-3 mb-2 ${
                    isProcessing ? "processing" : ""
                  }`}
                  style={{ height: "65vh" }}
                  onScroll={onScroll}
                >
                  {results.map((item, index) => (
                    <SearchedItem item={item} key={index} index={index} />
                  ))}
                  {isGettingMore ? (
                    <div className="center">
                      <img
                        src={loadIcon}
                        alt=""
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "fill",
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ height: isGetAll ? "0px" : "100px" }}></div>
                  )}
                </div>
              ) : isGettingMore ? (
                <div className="center" style={{ height: "74vh" }}>
                  <img
                    src={loadIcon}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="p-1 center flex-column"
                  style={{ height: "75vh" }}
                >
                  <BiFileBlank className="C-818188" size={100} />
                  <span className="F-16 C-dark">No Content Available!</span>
                </div>
              )}
            </div>
          </div>
        )}
      </Col>
    </>
  );
};

export default CurriculumSearch;
