import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import "@/pages/FormMenu/index.css";
import { AddEditModal } from "@/components";
import { clearFormDetailsReducer } from "@/reducers/form";
import {
  createForm,
  editFormNames,
  getFormDetails,
  getUserRoleList,
  uploadMediaImage,
  uploadFile,
} from "@/services";
import AddressBar from "@/components/Layout/AddressBar";
import { statusData, privacyLevelData, usePageTitle } from "@/helpers";
import { DropDown, Pressable } from "@/components/common";
import AddFormField from "./addFormFields";
import _ from "lodash";
import FormFieldListItem from "./formFieldListItem";
import { Editor } from "@tinymce/tinymce-react";

const AddForm = () => {
  const formData = useSelector((state) => {
    return {
      allRoleList: state.people.allRoleList,
      getFormDetailsData: state.form.getFormDetailsData,
      parentCategories: state.equipment.parentCategories,
      currentUser: state.auth.user,
    };
  });
  const { getFormDetailsData, parentCategories, allRoleList, currentUser } =
    formData;
  const userRole =
    allRoleList?.filter((item) => {
      return item?.level >= currentUser?.user_role?.level;
    }) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({});
  const {
    editID,
    status,
    name = "",
    is_public,
    accessLevel,
    saveID = [],
    statusField,
    keyName = "",
    isEdit = true,
    savedData = [],
    labelName = "",
    order = "",
    minValue = "",
    maxValue = "",
    prefixValue = "",
    suffixValue = "",
    formElementType,
    required = false,
    description = "",
    submit_message = "",
    placeholder = "",
    formModal = false,
    childFormModal = false,
    optionsData = [],
    rowOptionData = [],
    columnOptionData = [],
    parentValue = "",
    parentId = "",
    mainParentId = "",
    parentValueData,
    options,
    columnOption,
    rowOption,
    isNewFormModal = false,
    isCheckParentValue = false,
    otherDataValueOptions = [],
    otherDisplayValueOptions = [],
    informationEditorContent = "",
    scaleDataError = "",
    data_value,
    display_value,
    informationModalDisplay = "d-block",
    email_displayed = true,
    email_required = false,
  } = state;
  const [loading, setLoading] = useState(true);
  const [submitMessageLoading, setSubmitMessageLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const params = new URLSearchParams(location.search);
  const formIds = params.get("form-id");
  const informationRef = useRef(null);
  const labelNameRef = useRef(null);
  const submitMessageRef = useRef(null);

  useEffect(() => {
    if (formIds) {
      dispatch(getFormDetails(formIds)).then((res) => {
        if (res?.data && res?.data?.payload) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      dispatch(clearFormDetailsReducer());
      setLoading(false);
    }
    dispatch(getUserRoleList());
  }, [formIds]);

  usePageTitle(formIds ? "Edit Form" : "Create Form");

  const isMultipleChoiceGrid = ["multiple_choice_grid"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isLinearOption = ["linear"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isCheckBoxRadioOptions = ["checkbox", "radio"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isSelectOption = ["select"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isMultiOptions = ["checkbox", "radio", "select"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isOther = ["scale"]?.includes(state.formElementType?.toLowerCase());
  const isInformation = ["information"]?.includes(
    state.formElementType?.toLowerCase()
  );

  const isPrivacyPolicy =
    is_public === "Shared" ? is_public && accessLevel : is_public;
  const isDisabled = !name || !status || !isPrivacyPolicy;

  const switchBtnColor = (active) => {
    return active ? "Bg-success" : "Bg-danger";
  };

  const isDisabledFormField =
    // !labelName ||
    // !placeholder ||
    !keyName ||
    !statusField ||
    !formElementType ||
    !order ||
    (isOther &&
      (!otherDisplayValueOptions?.length || !otherDataValueOptions?.length)) ||
    (!isNewFormModal && isCheckParentValue && !parentValue?.length) ||
    (isMultiOptions
      ? !optionsData?.length
      : isMultipleChoiceGrid
      ? !rowOptionData?.length || !columnOptionData?.length
      : null) ||
    (isLinearOption &&
      (!minValue || !maxValue || !prefixValue || !suffixValue));

  useEffect(() => {
    if (!formModal) {
      setState((prev) => ({
        ...prev,
        keyName: "",
        required: "",
        labelName: "",
        placeholder: "",
        minValue: "",
        maxValue: "",
        prefixValue: "",
        suffixValue: "",
        order: "",
        statusField: "",
        formElementType: "",
        parentValue: "",
        optionsData: [],
        rowOptionData: [],
        columnOptionData: [],
        otherDataValueOptions: [],
        otherDisplayValueOptions: [],
        options: "",
        columnOption: "",
        rowOption: "",
        errorMessage: "",
        errorMessageRow: "",
        errorMessageColumn: "",
        scaleDataError: "",
        informationEditorContent: "",
      }));
    }
  }, [formModal]);

  useEffect(() => {
    if (getFormDetailsData) {
      const childElement = (mapElementData) => {
        if (!mapElementData) return [];
        return mapElementData?.map((elementItem) => {
          const multipleJsonData = elementItem?.options
            ? JSON.parse(elementItem?.options)
            : null;
          let optionsData = null;
          let rowOptionData = null;
          let columnOptionData = null;
          let otherDisplayValueOptions = null;
          let otherDataValueOptions = null;
          if (elementItem.type === "select") {
            optionsData = multipleJsonData?.map((option) => option?.label);
          } else if (elementItem.type === "multiple_choice_grid") {
            rowOptionData = multipleJsonData?.rows || [];
            columnOptionData = multipleJsonData?.columns || [];
          } else if (
            elementItem.type === "checkbox" ||
            elementItem.type === "radio"
          ) {
            optionsData = multipleJsonData;
          } else if (elementItem.type === "scale") {
            otherDisplayValueOptions = multipleJsonData?.display_value || [];
            otherDataValueOptions = multipleJsonData?.data_value || [];
          }
          let element = {
            id: elementItem?.id,
            labelName: elementItem?.label,
            keyName: elementItem?.key,
            placeholder: elementItem?.place_holder,
            order: elementItem?.order?.toString(),
            formElementType: elementItem?.type?.toLowerCase(),
            required: elementItem?.required,
            statusField: elementItem?.status?.toLowerCase(),
            optionsData: optionsData,
            rowOptionData: rowOptionData,
            columnOptionData: columnOptionData,
            otherDisplayValueOptions: otherDisplayValueOptions,
            otherDataValueOptions: otherDataValueOptions,
            parent_id: elementItem?.parent_id,
            ...(elementItem?.parent_value
              ? { parent_value: elementItem?.parent_value }
              : {}),
            ...(elementItem?.options
              ? { options: JSON.parse(elementItem?.options) }
              : {}),
            ...(elementItem?.elements?.length
              ? { elements: childElement(elementItem.elements) }
              : {}),
          };
          return element;
        });
      };
      const formElementData = getFormDetailsData?.form_elements?.map((item) => {
        const multipleJsonData = item?.options
          ? JSON.parse(item?.options)
          : null;
        let optionsData = null;
        let rowOptionData = null;
        let columnOptionData = null;
        let otherDisplayValueOptions = null;
        let otherDataValueOptions = null;
        if (item.type === "select") {
          optionsData = multipleJsonData?.map((option) => option?.label);
        } else if (item.type === "multiple_choice_grid") {
          rowOptionData = multipleJsonData?.rows || [];
          columnOptionData = multipleJsonData?.columns || [];
        } else if (item.type === "checkbox" || item.type === "radio") {
          optionsData = multipleJsonData;
        } else if (item.type === "scale") {
          otherDisplayValueOptions = multipleJsonData?.display_value || [];
          otherDataValueOptions = multipleJsonData?.data_value || [];
        } else {
          optionsData = multipleJsonData;
        }
        let elements = {
          id: item?.id,
          keyName: item?.key,
          labelName: item?.label,
          order: item?.order?.toString(),
          statusField: item?.status,
          placeholder: item?.place_holder,
          required: item?.required,
          formElementType: item?.type?.toLowerCase(),
          optionsData: optionsData,
          options: optionsData,
          rowOptionData: rowOptionData,
          columnOptionData: columnOptionData,
          otherDisplayValueOptions: otherDisplayValueOptions,
          otherDataValueOptions: otherDataValueOptions,
          parent_id: item?.parent_id,
          ...(item?.elements?.length
            ? { elements: childElement(item?.elements) }
            : {}),
        };
        return elements;
      });
      setState((prev) => ({
        ...prev,
        saveID: formElementData,
        savedData: formElementData,
        name: getFormDetailsData?.name || "",
        description: getFormDetailsData?.description || "",
        submit_message:
          getFormDetailsData?.submit_message ||
          "<h3 style='display: flex; justify-content: center; align-items: center;'>Thank you for submitting your form.</h3>",
        status: getFormDetailsData?.status?.toLowerCase() || "",
        is_public:
          +getFormDetailsData?.is_public === 0
            ? "private"
            : getFormDetailsData?.is_public === 1
            ? "shared"
            : "",
        accessLevel: getFormDetailsData?.access_level_id,
        email_displayed: getFormDetailsData?.email_displayed,
        email_required: getFormDetailsData?.email_required,
      }));
    }
  }, [getFormDetailsData]);

  useEffect(() => {
    if (savedData) {
      let val = savedData?.sort(
        (sortItemA, sortItemB) => sortItemA?.order - sortItemB?.order
      );
      setState((prev) => ({ ...prev, savedData: val }));
    }
  }, [savedData && savedData?.length, formModal]);

  if (!parentCategories?.includes("form")) {
    return <Navigate to="/dashboard" />;
  }

  const checkAndSetErrorMessage = (errorMessageKey, message) => {
    setState((prev) => ({ ...prev, [errorMessageKey]: message }));
  };

  const handleOnSubmitOptions = (valueKey, dataKey, errorMessageKey) => {
    const convertedArray = state?.[dataKey]?.map((item) =>
      item?.trim().toLowerCase()
    );
    if (!state?.[valueKey]) {
      checkAndSetErrorMessage(errorMessageKey, "Please Enter a value");
    } else if (
      convertedArray.includes(state?.[valueKey].trim().toLowerCase())
    ) {
      checkAndSetErrorMessage(
        errorMessageKey,
        "This Options field is already added."
      );
    } else {
      setState((prev) => ({
        ...prev,
        [valueKey]: "",
        [errorMessageKey]: "",
        [dataKey]: [...state?.[dataKey], state?.[valueKey]],
      }));
    }
  };

  const handleOnPressCancel = (item, key) => {
    const filterOptionsData = state?.[key]?.filter(
      (filterItem) => filterItem !== item
    );
    setState((prev) => ({
      ...prev,
      [key]: filterOptionsData,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let processedValue = value.trimStart();

    if (["order"].includes(name)) {
      processedValue = processedValue.replace(/\D/g, "");
    }

    setState((prev) => ({
      ...prev,
      [name]: processedValue,
      errorMessage: "",
      errorMessageRow: "",
      errorMessageColumn: "",
    }));
  };

  const handleSubmitForm = () => {
    let payload = {
      name: name,
      ...(formIds
        ? { description: description?.length ? description : "" }
        : description?.length
        ? { description }
        : {}),
      status: status?.toLowerCase() || "",
      is_public: is_public?.toLowerCase() === "shared",
      access_level_id: is_public?.toLowerCase() === "shared" ? accessLevel : 0,
      ...(formIds
        ? {
            submit_message: submitMessageRef?.current?.getContent()?.length
              ? submitMessageRef?.current?.getContent()
              : "",
          }
        : submitMessageRef?.current?.getContent()?.length
        ? { submit_message: submitMessageRef?.current?.getContent() }
        : {}),
      email_displayed,
      email_required,
    };
    if (savedData?.length) {
      const mapElements = (elements) => {
        if (!elements) return [];
        return elements.map((childItem) => ({
          ...(childItem?.newlyCreated ? {} : { id: childItem?.id }),
          key: childItem?.keyName,
          label: childItem?.labelName,
          order: childItem?.order || "",
          ...(childItem?.placeholder?.length
            ? { place_holder: childItem?.placeholder }
            : {}),
          type: childItem?.formElementType?.toLowerCase(),
          required: childItem?.required || false,
          status: childItem?.statusField?.toLowerCase(),
          ...(childItem?.parentValue
            ? { parent_value: childItem?.parentValue }
            : {}),
          ...(childItem?.options ? { options: childItem?.options } : {}),
          ...(childItem?.elements
            ? { elements: mapElements(childItem?.elements) }
            : {}),
        }));
      };
      const elementData = savedData?.map((item) => {
        let element = {
          ...(item?.parent ? {} : { id: item?.id }),
          key: item?.keyName || "",
          label: item?.labelName || "",
          order: item?.order || "",
          ...(item?.placeholder?.length
            ? { place_holder: item?.placeholder || "" }
            : {}),
          required: item?.required || false,
          status: item?.statusField?.toLowerCase() || "",
          type: item?.formElementType?.toLowerCase() || "",
          ...(item?.elements ? { elements: mapElements(item?.elements) } : {}),
        };
        if (item?.formElementType === "select") {
          const options = item?.optionsData?.map((option) => ({
            value: option.toLowerCase(),
            label: option,
          }));
          element.options = options;
        } else if (item?.formElementType === "multiple_choice_grid") {
          const rows = item?.rowOptionData || [];
          const columns = item?.columnOptionData || [];
          element.options = {
            rows,
            columns,
          };
        } else if (
          item?.formElementType === "radio" ||
          item?.formElementType === "checkbox"
        ) {
          element.options = item?.optionsData || [];
        } else if (item?.formElementType === "linear") {
          element.options = {
            min: item?.options?.min,
            max: item?.options?.max,
            prefix: item?.options?.prefix,
            suffix: item?.options?.suffix,
          };
        } else if (item?.formElementType === "scale") {
          const display_value = item?.otherDisplayValueOptions || [];
          const data_value = item?.otherDataValueOptions || [];
          element.options = {
            display_value,
            data_value,
          };
        } else if (item?.formElementType === "information") {
          element.options = item?.options;
        }
        return element;
      });
      if (elementData.length > 0) {
        payload.elements = elementData;
      }
    }
    if (!getFormDetailsData?.id && !formIds) {
      resposneFunction(dispatch(createForm(payload)), false);
    } else {
      resposneFunction(
        dispatch(editFormNames({ id: getFormDetailsData?.id, data: payload })),
        true
      );
    }
  };

  const resposneFunction = (apiCall, isEditRoute) => {
    setIsSubmitLoading(true);
    apiCall
      .unwrap()
      .then((res) => {
        if (res?.success) {
          if (!isEditRoute) {
            navigate("/forms");
          } else {
            navigate(-1);
          }
          toast.success(res?.message);
          setIsSubmitLoading(false);
        } else {
          setIsSubmitLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message || "Something went wrong!");
        setIsSubmitLoading(false);
      });
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 1000000000);
  };

  const handleFormModal = () => {
    setState((prev) => ({
      ...prev,
      formModal: true,
      childFormModal: false,
      parentId: "",
      isEdit: false,
      isNewFormModal: true,
    }));
  };

  const testId = !formIds ? "addForm" : "editForm";

  const handleChildSave = (oldData, childData) => {
    const newData = oldData.map((item) => {
      if (item.id === childData.parentId) {
        let newItem = { ...item };
        newItem["elements"] = newItem.elements
          ? [...newItem.elements, childData]
          : [childData];
        return newItem;
      } else {
        if (item?.elements?.length > 0) {
          const updatedElements = handleChildSave(item.elements, childData);
          return { ...item, elements: updatedElements };
        } else {
          return item;
        }
      }
    });
    return newData;
  };

  const checkBoxOptions = state.optionsData?.map((item) => {
    return {
      value: item?.toLowerCase(),
      label: item,
    };
  });

  const editElement = (elements, editedElement) => {
    return elements.map((item) => {
      if (item.id == editedElement.id) {
        return {
          ...item,
          id: item.id,
          keyName: editedElement.keyName,
          required: editedElement.required,
          labelName: editedElement.labelName,
          order: editedElement.order,
          placeholder: editedElement.placeholder,
          statusField: editedElement.statusField?.toLowerCase(),
          parentValue: parentValue,
          optionsData: editedElement.optionsData,
          rowOptionData: editedElement.rowOptionData,
          columnOptionData: editedElement.columnOptionData,
          formElementType: editedElement.formElementType?.toLowerCase(),
          otherDisplayValueOptions: editedElement.otherDisplayValueOptions,
          otherDataValueOptions: editedElement.otherDataValueOptions,
          ...(isLinearOption
            ? {
                options: {
                  min: minValue,
                  max: maxValue,
                  prefix: prefixValue,
                  suffix: suffixValue,
                },
              }
            : {}),
          ...(isMultipleChoiceGrid
            ? {
                options: {
                  rows: editedElement.rowOptionData,
                  columns: editedElement.columnOptionData,
                },
              }
            : {}),
          ...(isCheckBoxRadioOptions
            ? { options: editedElement.optionsData }
            : {}),
          ...(isSelectOption ? { options: checkBoxOptions } : {}),
          ...(isOther
            ? {
                options: {
                  display_value: editedElement.otherDisplayValueOptions,
                  data_value: editedElement.otherDataValueOptions,
                },
              }
            : {}),
          ...(isInformation
            ? { options: informationRef?.current?.getContent() }
            : {}),
        };
      } else if (item.elements) {
        return {
          ...item,
          elements: editElement(item.elements, editedElement),
        };
      }
      return item;
    });
  };

  const hideShowInformationModal = (value) => {
    setState((prev) => ({ ...prev, informationModalDisplay: value }));
  };

  const handleFormField = () => {
    if (
      formElementType &&
      ["select", "checkbox", "radio"].includes(formElementType) &&
      optionsData?.length < 2
    ) {
      toast.error("Add at least 2 options!");
    }
    //  else if (isInformation && !informationRef?.current?.getContent()) {
    //   toast.error("Please Enter Information Content");
    // }
    else if (!labelNameRef?.current?.getContent()) {
      toast.error("Please Enter Label Name");
    } else {
      const isValidateRequired = isInformation ? false : required;
      if (!isEdit) {
        let data = {
          keyName: keyName,
          required: isValidateRequired,
          labelName: labelNameRef?.current?.getContent(),
          order: order,
          placeholder: placeholder,
          id: generateRandomNumber(),
          formElementType: formElementType.toLowerCase() || "",
          parentValue: parentValue,
          parentId: parentId,
          statusField: statusField.toLowerCase() || "",
          optionsData: optionsData,
          rowOptionData: rowOptionData,
          columnOptionData: columnOptionData,
          otherDisplayValueOptions: otherDisplayValueOptions,
          otherDataValueOptions: otherDataValueOptions,
          ...(isLinearOption
            ? {
                options: {
                  min: minValue,
                  max: maxValue,
                  prefix: prefixValue,
                  suffix: suffixValue,
                },
              }
            : {}),
          ...(isMultipleChoiceGrid
            ? {
                options: {
                  rows: rowOptionData,
                  columns: columnOptionData,
                },
              }
            : {}),
          ...(isOther
            ? {
                options: {
                  display_value: otherDisplayValueOptions,
                  data_value: otherDataValueOptions,
                },
              }
            : {}),
          ...(isCheckBoxRadioOptions ? { options: optionsData } : {}),
          ...(isSelectOption ? { options: checkBoxOptions } : {}),
          ...(isInformation
            ? { options: informationRef?.current?.getContent() }
            : {}),
          ...(state.childFormModal ? { newlyCreated: true } : {}),
          parent: true,
          ...(state?.childFormModal
            ? { parent_id: generateRandomNumber() }
            : {}),
        };
        if (state?.childFormModal) {
          const childData = handleChildSave(savedData, data);
          setState((prev) => ({
            ...prev,
            formModal: false,
            savedData: childData,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            formModal: false,
            savedData: [...savedData, data],
          }));
        }
      } else {
        if (isEdit) {
          const editData = editElement(savedData, {
            id: editID,
            keyName: keyName,
            required: isValidateRequired,
            labelName: labelNameRef?.current?.getContent(),
            order: order,
            placeholder: placeholder,
            optionsData: optionsData,
            parentValue: parentValue,
            statusField: statusField.toLowerCase() || "",
            formElementType: formElementType?.toLowerCase() || "",
            rowOptionData: rowOptionData,
            columnOptionData: columnOptionData,
            otherDisplayValueOptions: otherDisplayValueOptions,
            otherDataValueOptions: otherDataValueOptions,
            informationEditorContent: informationRef?.current?.getContent(),
          });
          setState((prev) => ({
            ...prev,
            formModal: false,
            savedData: editData,
          }));
        }
      }
    }
  };

  const removeElement = (elements, id) => {
    return elements.filter((item) => {
      if (item.id === id) {
        return false;
      } else if (item.elements) {
        item.elements = removeElement(item.elements, id);
        return true;
      } else {
        return true;
      }
    });
  };

  const handleFormFieldDelete = (item) => {
    const updatedFormFieldsList = removeElement(savedData, item?.id);
    setState((prev) => ({ ...prev, savedData: updatedFormFieldsList }));
  };

  const findElementAndParent = (elements, childId, parent = null) => {
    let result = { child: null, parent };
    elements.some((item) => {
      if (item.id === childId) {
        result = { child: item, parent };
        return true;
      } else if (item.elements) {
        const { child, parent: newParent } = findElementAndParent(
          item.elements,
          childId,
          item
        );
        if (child) {
          result = { child, parent: newParent };
          return true;
        }
      }
      return false;
    });
    return result;
  };

  const handleFormFieldUpdate = (item) => {
    const isParent =
      (item.hasOwnProperty("parentId") && item.parentId !== "") ||
      (item.hasOwnProperty("parent_value") && item?.parent_value !== "");
    if (item?.parent_value) {
      const { parent } = findElementAndParent(savedData, item?.id);
      setState((prev) => ({
        ...prev,
        parentValueData: parent?.optionsData,
      }));
    }
    const checkIsArray = Array.isArray(item?.optionsData);
    setState((prev) => ({
      ...prev,
      isEdit: true,
      formModal: true,
      editID: item?.id,
      keyName: item?.keyName,
      required: item?.required,
      labelName: item?.labelName,
      order: item?.order,
      minValue: item?.options?.min,
      maxValue: item?.options?.max,
      prefixValue: item?.options?.prefix,
      suffixValue: item?.options?.suffix,
      placeholder: item?.placeholder,
      formElementType: item?.formElementType || "",
      ...(checkIsArray ? { optionsData: item?.optionsData } : {}),
      parentValue: item?.parentValue || item?.parent_value,
      statusField: item?.statusField.toLowerCase() || "",
      rowOptionData: item?.rowOptionData,
      columnOptionData: item?.columnOptionData,
      isNewFormModal: false,
      isCheckParentValue: isParent,
      otherDisplayValueOptions: item?.otherDisplayValueOptions,
      otherDataValueOptions: item?.otherDataValueOptions,
      informationEditorContent: item?.options,
    }));
  };

  const handleOnChangeSelect = (e) => {
    const { name, id } = e.target;
    const selectValue = JSON.parse(id);

    setState((prev) => ({
      ...prev,
      ...(name === "is_public" && { accessLevel: "" }),
      [name]: name === "accessLevel" ? selectValue.id : selectValue.name,
    }));
  };

  const handleChildFormModal = (item) => {
    setState((prev) => ({
      ...prev,
      formModal: true,
      childFormModal: true,
      parentValueData: item?.optionsData,
      parentId: item?.id,
      isEdit: false,
      isNewFormModal: false,
      isCheckParentValue: true,
    }));
  };

  const handleDragEndData = (key, item, otherDataKey, otherItemData) => {
    setState((prev) => ({ ...prev, [key]: item }));
    if (otherDataKey) {
      setState((prev) => ({ ...prev, otherDataValueOptions: otherItemData }));
    }
  };

  const handleDragEndDataForSavedData = (item) => {
    if (item) {
      const changeOrder = item?.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
      setState((prev) => ({ ...prev, savedData: changeOrder }));
    }
  };

  const handleEditorInit = (editor, editRef) => {
    submitMessageRef.current = editRef;
  };

  const setDataEditor = (url, cb, type) => {
    if (type?.type === "audio/mpeg") {
      var audioHtml = "<audio controls>";
      audioHtml += '<source src="' + url + '" type="' + type.type + '">';
      audioHtml += "Your browser does not support the audio element.";
      audioHtml += "</audio>";
      window.tinymce.activeEditor.insertContent(audioHtml);
      window.tinymce.activeEditor.windowManager.close();
    } else {
      cb(url, {
        title: type.name,
      });
    }
  };

  const handleFileChange = async (e, cb) => {
    const file = e?.[0];
    if (!file) return;
    if (
      file.type === "image/heic" ||
      file.name.toLowerCase().endsWith(".heic")
    ) {
      toast.info(
        "HEIC files are not supported. Please select a different file."
      );
      return;
    }
    if (file.size > 209715200) {
      toast.info("File size should be less than 200MB");
      return;
    }
    setSubmitMessageLoading(true);
    const imageTypes = ["image/jpeg", "image/png", "image/webp", "image/gif"];
    const isImage = imageTypes.includes(file.type);
    const data = new FormData();
    if (isImage) {
      data.append("image", file, file.name);
    } else {
      data.append("file", file, file.name);
    }
    try {
      let res;
      if (isImage) {
        res = await dispatch(uploadMediaImage({ filess: data })).unwrap();
      } else {
        res = await dispatch(uploadFile(data)).unwrap();
      }
      if (res.url) {
        setSubmitMessageLoading(false);
        setDataEditor(res.url, cb, file);
      } else {
        toast.info("File content too large.");
      }
    } catch (error) {
      setSubmitMessageLoading(false);
      toast.error("File upload failed.");
    }
  };

  const handleEmailDisplayed = () => {
    setState((prev) => {
      const newEmailDisplayed = !prev.email_displayed;
      return {
        ...prev,
        email_displayed: newEmailDisplayed,
        email_required: newEmailDisplayed ? prev.email_required : false,
      };
    });
  };

  const handleEmailRequired = () => {
    setState((prev) => {
      const newEmailRequired = !prev.email_required;
      return {
        ...prev,
        email_required: newEmailRequired,
        email_displayed: newEmailRequired ? true : prev.email_displayed,
      };
    });
  };

  return (
    <>
      <AddressBar
        page={[
          { name: "Forms", link: "/forms" },
          { name: !formIds ? "Add-Form" : "Edit-Form" },
        ]}
      />
      {loading ? (
        <div className="center" style={{ height: window.innerHeight - 250 }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="row p-2 m-0 Bg-fff rounded mt-2 Shadow">
          <Form onSubmit={handleSubmitForm}>
            <Form.Group className="mb-3">
              <Row className="mx-3 gx-5">
                <Col
                  xs={12}
                  sm={12}
                  lg={is_public?.toLowerCase() === "shared" ? 6 : 4}
                  xl={is_public?.toLowerCase() === "shared" ? 3 : 4}
                  className="mt-4"
                >
                  <label class="itemText lable mt-2 C-dark form-label">
                    Name*
                  </label>
                  <input
                    name="name"
                    value={name}
                    maxLength={100}
                    autocomplete="off"
                    lableclass="mb-0 mt-2"
                    onChange={handleChange}
                    placeholder="Enter Name"
                    className="add-form-input p-2"
                    id={`${testId}.textInput.name`}
                  ></input>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  lg={is_public?.toLowerCase() === "shared" ? 6 : 4}
                  xl={is_public?.toLowerCase() === "shared" ? 3 : 4}
                  className="mt-4"
                >
                  <label className="itemText lable mt-2 C-dark form-label">
                    Status*
                  </label>
                  <DropDown
                    noShadow
                    name="status"
                    value={_.capitalize(status)}
                    data={statusData}
                    placeholder="Choose Status"
                    onChange={handleOnChangeSelect}
                    style={{
                      height: "35px",
                    }}
                    toggleId={`${testId}.dropDown.status`}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  lg={is_public?.toLowerCase() === "shared" ? 6 : 4}
                  xl={is_public?.toLowerCase() === "shared" ? 3 : 4}
                  className="mt-4"
                >
                  <label className="itemText lable mt-2 C-dark form-label">
                    Privacy Level*
                  </label>
                  <DropDown
                    noShadow
                    name="is_public"
                    value={_.capitalize(is_public)}
                    data={privacyLevelData}
                    placeholder="Choose Privacy Level"
                    onChange={handleOnChangeSelect}
                    style={{
                      height: "35px",
                    }}
                    toggleId={`${testId}.dropDown.privacy`}
                  />
                </Col>
                {is_public?.toLowerCase() === "shared" ? (
                  <Col xs={12} sm={12} lg={6} xl={3} className="mt-4">
                    <label className="itemText lable mt-2 C-dark form-label">
                      Access Level*
                    </label>
                    <DropDown
                      noShadow
                      name="accessLevel"
                      value={_.capitalize(
                        userRole?.find((item) => item.id === accessLevel)?.name
                      )}
                      data={userRole}
                      placeholder="Choose Access Level"
                      onChange={handleOnChangeSelect}
                      style={{
                        height: "35px",
                      }}
                    />
                  </Col>
                ) : null}
              </Row>
              <Row className="my-lg-3 my-sm-3 mx-4">
                <Col xs={12} sm={12} lg={12}>
                  <label class="itemText lable mt-2 C-dark form-label">
                    Description
                  </label>
                  <Form.Control
                    rows={4}
                    as="textarea"
                    name="description"
                    value={description}
                    onChange={handleChange}
                    maxLength={500}
                    style={{ fontSize: "13px" }}
                    placeholder="Enter Description"
                    className="form-controls"
                    id={`${testId}.textArea.description`}
                  />
                </Col>
              </Row>
              <Row className="m-3 mx-4">
                <Col xs={6} className="d-flex align-items-center">
                  <label className="itemText lable C-dark form-label me-5">
                    Email Displayed
                  </label>
                  <div>
                    <input
                      id="switch"
                      name="email_displayed"
                      type="checkbox"
                      value={email_displayed}
                      checked={email_displayed}
                      onChange={handleChange}
                      className="switch-checkbox"
                    />
                    <button
                      type="button"
                      className={`switch-label`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleEmailDisplayed();
                      }}
                    >
                      <span
                        className={`switch-button ${switchBtnColor(
                          email_displayed
                        )}`}
                      />
                    </button>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  lg={6}
                  className="d-flex align-items-center"
                >
                  <label className="itemText lable C-dark form-label me-5">
                    Email Required
                  </label>
                  <div>
                    <input
                      id="switch"
                      name="email_required"
                      type="checkbox"
                      value={email_required}
                      checked={email_required}
                      onChange={handleChange}
                      className="switch-checkbox"
                    />
                    <button
                      type="button"
                      className={`switch-label`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleEmailRequired();
                      }}
                    >
                      <span
                        className={`switch-button ${switchBtnColor(
                          email_required
                        )}`}
                      />
                    </button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-lg-3 mt-sm-3 mx-4">
                <Col xs={12} sm={12} lg={12}>
                  <label class="itemText lable mt-2 C-dark form-label">
                    Submit Message
                  </label>
                  {submitMessageLoading
                    ? toast.loading(
                        "Please wait while we process your media...",
                        {
                          position: "top-center",
                          style: {
                            backgroundColor: "#1471B0",
                            color: "#fff",
                            width: "380px",
                          },
                        }
                      )
                    : toast.dismiss()}
                  <Editor
                    tinymceScriptSrc="/path/to/tinymce.min.js"
                    initialValue={submit_message}
                    onInit={handleEditorInit}
                    init={{
                      height: 300,
                      menubar: true,
                      statusbar: false,
                      branding: false,
                      resize: true,
                      browser_spellcheck: true,
                      contextmenu: false,
                      plugins:
                        "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons fontselect",
                      toolbar:
                        "undo redo | bold italic underline strikethrough | formatselect | fontselect | fontsizeselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | indent outdent | bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample | ltr rtl | table | code ",
                      toolbar_mode: "sliding",
                      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                      forced_root_block: "p",
                      newline_behavior: "block",
                      autosave_ask_before_unload: false,
                      image_title: true,
                      file_picker_types: "image",
                      file_picker_callback: function (cb, value, meta) {
                        const input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                          const fileData = this?.files;
                          setTimeout(() => {
                            handleFileChange(fileData, cb);
                          }, 10);
                        };
                        input.click();
                      },
                      content_style: "* { margin: 0.02rem; line-height: 1.5; }",
                    }}
                  />
                </Col>
              </Row>
              {savedData && savedData.length > 0 && (
                <Row className="mx-4 mt-3">
                  <Col>
                    <div className="m-0 rounded mt-2 Shadow">
                      <Table responsive className="mb-0 border-bottom-none">
                        <thead>
                          <tr style={{ borderBottom: "3px solid #cdcdcd" }}>
                            <th></th>
                            <th className="col-xs-1 col-sm-1 col-lg-1 C-primary">
                              Id
                            </th>
                            <th className="col-xs-3 col-sm-3 col-lg-4 C-primary">
                              Fields Name
                            </th>
                            <th className="col-xs-3 col-sm-3 col-lg-4 C-primary">
                              Fields Type
                            </th>
                            <th className="col-xs-3 col-sm-3 col-lg-4 C-primary">
                              Status
                            </th>
                            <th className="col-xs-2 col-sm-2 col-lg-3 C-primary">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <FormFieldListItem
                          savedData={savedData}
                          handleDragEndDataForSavedData={
                            handleDragEndDataForSavedData
                          }
                          handleChildFormModal={handleChildFormModal}
                          handleFormFieldUpdate={handleFormFieldUpdate}
                          handleFormFieldDelete={handleFormFieldDelete}
                          testId={testId}
                        />
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mx-4">
                <div className="mt-5">
                  <Pressable
                    classes="w-25"
                    title="Form Fields +"
                    onPress={handleFormModal}
                    style={{ minWidth: "80px", maxWidth: "150px" }}
                    id={`${testId}.button.addFields`}
                  />
                </div>
              </Row>
              <Row className=" mt-3 mx-4">
                <div className="d-flex justify-content-end">
                  <div className="px-3">
                    <Pressable
                      title="Cancel"
                      onPress={() => navigate("/forms")}
                      classes="bg-light text-black w-25"
                      style={{ minWidth: "100px", maxWidth: "170px" }}
                      id={`${testId}.button.cancel`}
                    />
                  </div>
                  <div>
                    <Pressable
                      title="Save"
                      classes="w-25"
                      loading={isSubmitLoading}
                      disabled={isDisabled}
                      onPress={handleSubmitForm}
                      style={{ minWidth: "100px", maxWidth: "170px" }}
                      id={`${testId}.button.save`}
                    />
                  </div>
                </div>
              </Row>
            </Form.Group>
            <div>
              <Row>
                {formModal && (
                  <AddEditModal
                    id={`${testId}`}
                    name="Element"
                    show={formModal}
                    onSubmit={handleFormField}
                    type={isEdit ? "Edit" : "Add"}
                    isDisableSave={isDisabledFormField}
                    closeModal={() => setState({ ...state, formModal: false })}
                    className={`${informationModalDisplay}`}
                  >
                    <AddFormField
                      state={state}
                      setState={setState}
                      handleChange={handleChange}
                      handleOnSubmitOptions={handleOnSubmitOptions}
                      handleOnPressCancel={handleOnPressCancel}
                      handleDragEndData={handleDragEndData}
                      id={`${testId}Modal`}
                      ref={informationRef}
                      labelNameRef={labelNameRef}
                      content={informationEditorContent}
                      hideShowModal={hideShowInformationModal}
                    />
                  </AddEditModal>
                )}
              </Row>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};
export default AddForm;
