import { isSuperAdmin, setUserToken } from "@/helpers";
import React, { lazy, Suspense, useLayoutEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getTimeZoneList,
  getUserProfile,
  logout,
  updateProfile,
  logoutApi
} from "@/services";
import TimezoneModal from "../TimezoneModal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./topbar.css";
import "../../index.css";
const SideNav = lazy(() => import("./SideNav"));

function DefaultLayout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    dashboardLoader: dashboardLoading,
    webApp: currentWebApp,
    timeZoneList,
    user: userData,
  } = useSelector((state) => state.auth);
  const parentCategories = useSelector((state) => state.equipment.parentCategories)
  const [state, setState] = useState({});
  const { timeZone, showTimeZoneModal } = state;
  const isCurriculum = window.location.pathname.includes("curriculum");

  const toggleSideNav = (e) => {
    let el = document.getElementById("sideNav");
    e?.target.checked
      ? el.classList.remove("sideNav", "isSearchNav")
      : el.classList.add("sideNav", `${props.isSearch && "isSearchNav"}`);

    if (props.isSearch) {
      let content = document.getElementById("content");
      e?.target.checked
        ? content.classList.replace("fullBody", "body")
        : content.classList.replace("body", "fullBody");
    }
  };

  useEffect(() => {
    if (userData?.timezone_id === null) {
      setState({ ...state, showTimeZoneModal: true });
    } else {
      setState({ ...state, showTimeZoneModal: false });
    }
    if (!timeZoneList && !isCurriculum) {
      dispatch(getTimeZoneList());
    }
  }, []);

  useEffect(() => {
      if (window.location.pathname?.includes("/curriculum/")) {
        let content = document.getElementById("content");
        content.classList.replace("body", "curriculumBody");
      }
      
      if (window.location.pathname.includes("curriculum")) {
      const styleElement = document.createElement("style");
      styleElement.innerHTML = "body::-webkit-scrollbar { display: none; }";
      styleElement.innerHTML =
      "body::-webkit-scrollbar-thumb { background: var(--primary-color); }";
      document.head.appendChild(styleElement);

      document.body.classList.add("curr");
      return () => {
        // Clean up the injected <style> element when the component is unmounted
        document.head.removeChild(styleElement);
      };
  }
  }, [window.location.pathname]);
  useLayoutEffect(() => {
    if(!userData?.timezone_id && !parentCategories?.includes('curriculum') && window.location.pathname.includes('curriculum')){
      setTimeout(() => {
        navigate('/dashboard')
      }, 0);
    }
  }, [userData, window.location.pathname])

  const handleChange = (value) => {
    setState({ ...state, timeZone: value });
  };
  const handleSubmit = () => {
    const payload = {
      timezone_id: timeZone?.id,
    };
    if (timeZone?.id) {
      dispatch(updateProfile(payload))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            toast.success(res?.message);
            dispatch(getUserProfile());
            let token = JSON.parse(localStorage.getItem("token"));
            token.user = res;
            setUserToken(token);
            setState({ ...state, showTimeZoneModal: false });
          }
        });
    } else {
      toast.error("Please select timezone");
    }
  };
  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    dispatch(logoutApi());
    return navigate("/", { replace: true });
  };
  return (
    <>
      <Suspense>
        <SideNav isSearch={props.isSearch} toggleSideNav={toggleSideNav} />
      </Suspense>
      <div className="wrapper d-flex flex-column flex-grow-1 ">
        <div
          style={{ position: "relative" }}
          className={`${props.isSearch ? "fullBody" : "body"} ${isSuperAdmin() ? "mt-5" : "mt-0"
            } flex-grow-1 px-3 pb-5 d-flex flex-column mt-sm-0 curriculum_outer ${window.location.pathname.includes("curriculum") ? "pe-0" : "px-3"
            }`}
          id="content"
        >
          {loading ? (
            <div className="d-flex center flex-grow-1">
              <Spinner
                animation="border"
                className="d-flex align-self-center"
              />
            </div>
          ) : (
            props.children
          )}
          {dashboardLoading && (
            <div style={{ position: "fixed", top: "48%", left: "49%" }}>
              <Spinner
                animation="border"
                className="d-flex align-self-center"
              />
            </div>
          )}
        </div>
        {window.location.pathname.includes("curriculum") ? "" : <Footer />}
        {
          <TimezoneModal
            showTimeZoneModal={showTimeZoneModal}
            moduleName={"Timezone"}
            handleChange={handleChange}
            data={timeZoneList}
            value={timeZone}
            handleSubmit={handleSubmit}
            handleLogout={handleLogout}
          />
        }
      </div>
    </>
  );
}

export default DefaultLayout;
