import { getUsersList } from "@/services/people.service";
import { createSlice } from "@reduxjs/toolkit";
import {
  addLocation,
  getActivityList,
  getAvailableLocationList,
  getComplianceStatusLevelList,
  getComplianceStatusList,
  getLocationDetail,
  getLocationEquipments,
  getLocationFilters,
  getLocationPeople,
  getLocations,
} from "../services/location.service";

const initialState = {
  locationList: null,
  availableLocationList: null,
  locationDetail: null,
  usersList: null,
  locationPeople: null,
  locationEquipmens: [],
  activityList: null,
  complianceStatusList: null,
  complianceStatusLevelList: null,
  getLocationFiltersList: null,
};

const location = createSlice({
  name: "location",
  initialState,
  reducers: {
    addLocationToList: (state, action) => {
      state.locationList = [...state.locationList, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLocation.fulfilled, (state, action) => {
        return {
          ...state,
          initialState: { data: action.payload },
        };
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locationList = action.payload?.data;
      })
      .addCase(getAvailableLocationList.fulfilled, (state, action) => {
        if (action.payload && action.payload?.data) {
          return {
            ...state,
            availableLocationList: action.payload.data,
          };
        } else {
          return initialState;
        }
      })
      .addCase(getLocationDetail.fulfilled, (state, action) => {
        state.locationDetail = action.payload?.data;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.usersList = action.payload?.data?.data;
      })
      .addCase(getLocationPeople.fulfilled, (state, action) => {
        state.locationPeople = action.payload?.data;
      })
      .addCase(getLocationFilters.fulfilled, (state, action) => {
        state.getLocationFiltersList = action.payload?.data;
      })
      .addCase(getLocationEquipments.fulfilled, (state, action) => {
        state.locationEquipmens = action.payload?.data;
      })
      .addCase(getActivityList.fulfilled, (state, action) => {
        state.activityList = action.payload?.data;
      })
      .addCase(getComplianceStatusList.fulfilled, (state, action) => {
        state.complianceStatusList = action.payload?.data;
      })
      .addCase(getComplianceStatusLevelList.fulfilled, (state, action) => {
        state.complianceStatusLevelList = action.payload?.data;
      });
  },
});

export default location.reducer;
