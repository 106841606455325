import React, { useEffect, useRef } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  pusher,
  useGlobalEvents,
  isUserAuthenticated,
  getLoggedInUserToken,
} from "helpers";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./theme/Colors.css";
import { getLicensePermissions, getUserProfile, logout } from "./services";
import { updateNotificationData } from "./reducers/dashboard";
import "./App.css";
import "./index.css";
import "./common.css";

import "@pages/auth/index.css";
import { router as ShRouter } from "./routes/routes";
import moment from "moment";
import {
  expiredTokenModalActionDispatcher,
  refreshTokenActionDispatcher,
} from "./helpers";

const App = () => {
  const dispatch = useDispatch();
  useGlobalEvents();
  const currentUserId = useSelector((state) => state.auth.user?.id);
  const isRefreshingToken = useRef(false);
  const isCurriculum = window.location.pathname.includes("curriculum");

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe("notification");
    channel.bind("new", function (data) {
      if (+data?.notification?.user_id === currentUserId) {
        dispatch(updateNotificationData(data));
      }
    });

    return () => channel.unbind();
  }, []);

  useEffect(() => {
    if (isUserAuthenticated() && !isCurriculum) {
      // Req user for notification permission
      dispatch(getLicensePermissions());
      dispatch(getUserProfile());
    }
  }, [isUserAuthenticated()]);

  useEffect(() => {
    setTimeout(() => {
      const color = window.location.href?.includes("curriculum")
        ? "#1471b0"
        : "#c60970";
      document.documentElement.style.setProperty("--primary-color", color);
      document.documentElement.style.setProperty("--bg-primary-color", color);
    }, 100);
  }, [window.location.href]);

  const handleNavigateToLogin = () => {
    window.location.href = "/";
    logout();
    expiredTokenModalActionDispatcher();
  };

  const checkTokenExpiration = async () => {
    const { expiresAt, refreshToken } = getLoggedInUserToken() || {};

    const expirationTime = moment.utc(expiresAt, "YYYY-MM-DD HH:mm:ss");
    const currentTime = moment.utc();
    const timeDifference = currentTime.isBefore(expirationTime);

    if (getLoggedInUserToken() && refreshToken) {
      if (timeDifference && !isRefreshingToken.current) {
        isRefreshingToken.current = true;
        await refreshTokenActionDispatcher();
        setTimeout(() => {
          isRefreshingToken.current = false;
        }, 5000);
      } else {
        handleNavigateToLogin();
      }
    } else if (getLoggedInUserToken() && !refreshToken) {
      handleNavigateToLogin();
    }
  };

  return (
    <React.Fragment>
      <RouterProvider router={ShRouter} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"colored"}
      />
    </React.Fragment>
  );
};

export default App;
