import { IoMdAdd } from "react-icons/io";
import { Col, Row } from "react-bootstrap";
import {
  statusData,
  formTypeData,
  minimumValueData,
  maximumValueData,
} from "@/helpers";
import { DropDown, TextInput } from "@/components/common";
import _ from "lodash";
import { DroppableContext } from "@/components";
import { Editor } from "@tinymce/tinymce-react";
import { forwardRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadMediaImage, uploadFile } from "@/services";

const AddFormField = forwardRef((props, ref) => {
  const {
    labelName,
    placeholder,
    keyName,
    required,
    order,
    statusField,
    formElementType,
    options,
    rowOption,
    minValue,
    maxValue,
    prefixValue,
    suffixValue,
    columnOption,
    optionsData,
    rowOptionData,
    columnOptionData,
    parentValueData,
    errorMessage,
    errorMessageRow,
    errorMessageColumn,
    childFormModal,
    parentId,
    parentValue,
    isNewFormModal,
    isCheckParentValue,
    otherDataValueOptions,
    otherDisplayValueOptions,
    data_value,
    display_value,
    scaleDataError,
    informationEditorContent,
  } = props.state;
  const {
    setState,
    handleChange,
    handleOnSubmitOptions,
    handleOnPressCancel,
    handleDragEndData,
    id,
    content,
    onContentChange,
    hideShowModal,
    labelNameRef,
  } = props;

  const [loading, setLoading] = useState(false);

  //   const isOptionsShow = ['select', 'checkbox', 'radio']?.includes(
  //     formElementType?.toLowerCase() || data?.type?.toLowerCase(), //data is missing
  //   );

  const isOptionsShow = ["select", "checkbox", "radio"]?.includes(
    formElementType?.toLowerCase()
  );
  const isMultipleChoiceGrid = ["multiple_choice_grid"]?.includes(
    formElementType?.toLowerCase()
  );
  const isLinear = ["linear"]?.includes(formElementType?.toLowerCase());
  const isScale = ["scale"]?.includes(formElementType?.toLowerCase());
  const isInformation = ["information"]?.includes(
    formElementType?.toLowerCase()
  );

  let switchBtnColor = required ? "Bg-success" : "Bg-danger";
  const dispatch = useDispatch();

  const handleInput = (e, values) => {
    const { name, value } = e.target;
    let processedValue = value.trimStart();

    if (["data_value"]?.includes(name)) {
      processedValue = processedValue.replace(/\D/g, "");
    }
    setState((prev) => ({
      ...prev,
      [values]: processedValue,
    }));
  };

  const handleFormSwitchBtn = () => {
    setState((prev) => ({
      ...prev,
      required: !required,
    }));
  };

  const handleChoseType = (e) => {
    const value = JSON.parse(e.target.id);
    setState((prev) => ({
      ...prev,
      formElementType: value?.key,
      optionsData: [],
      rowOptionData: [],
      columnOptionData: [],
      otherDisplayValueOptions: [],
      otherDataValueOptions: [],
      errorMessage: "",
      errorMessageRow: "",
      errorMessageColumn: "",
      options: "",
      columnOption: "",
      rowOption: "",
      minValue: "",
      maxValue: "",
      prefixValue: "",
      suffixValue: "",
      display_value: "",
      data_value: "",
      informationEditorContent: "",
    }));
  };

  const handleSelectOption = (e) => {
    const value = JSON.parse(e.target.id);
    setState((prev) => ({ ...prev, [e.target.name]: value?.name }));
  };

  const handleChosePrivateValue = (e) => {
    let value = e.target.id === "{}" ? "" : e.target.id;
    setState((prev) => ({ ...prev, parentValue: value }));
  };

  const handleKeyPress = (e, type, option, optionData, errorMessage) => {
    if (e.key === "Enter") {
      if (type) {
        handleOnSubmitOptions(option, optionData, errorMessage);
      }
    }
  };

  const onClearAllOptions = () => {
    setState((prev) => ({
      ...prev,
      optionsData: [],
      rowOptionData: [],
      columnOptionData: [],
      otherDataValueOptions: [],
      otherDisplayValueOptions: [],
      data_value: "",
      display_value: "",
    }));
  };

  const handleAddOtherOptions = () => {
    if (!display_value && !data_value) {
      setState((prev) => ({
        ...prev,
        scaleDataError: "Please add at least one display value and data value.",
      }));
    } else if (!display_value) {
      setState((prev) => ({
        ...prev,
        scaleDataError: "Please enter display value.",
      }));
    } else if (!data_value) {
      setState((prev) => ({
        ...prev,
        scaleDataError: "Please enter data value.",
      }));
    } else if (otherDisplayValueOptions?.includes(display_value)) {
      setState((prev) => ({
        ...prev,
        scaleDataError: "This display value is already added.",
      }));
    } else if (otherDataValueOptions?.includes(data_value)) {
      setState((prev) => ({
        ...prev,
        scaleDataError: "This data value is already added.",
      }));
    } else {
      setState((prev) => ({
        ...prev,
        otherDisplayValueOptions: [
          ...prev.otherDisplayValueOptions,
          display_value,
        ],
        otherDataValueOptions: [...prev.otherDataValueOptions, data_value],
        display_value: "",
        data_value: "",
        scaleDataError: "",
      }));
    }
  };

  const handleClearOtherOption = (index) => {
    setState((prev) => {
      const updatedDataValueOptions = [...prev.otherDataValueOptions];
      updatedDataValueOptions.splice(index, 1);

      const updatedDisplayValueOptions = [...prev.otherDisplayValueOptions];
      updatedDisplayValueOptions.splice(index, 1);

      return {
        ...prev,
        otherDataValueOptions: updatedDataValueOptions,
        otherDisplayValueOptions: updatedDisplayValueOptions,
      };
    });
  };

  const testId = formElementType?.includes("_")
    ? _.camelCase([...formElementType?.split("_")].concat())
    : formElementType;

  const handleEditorInit = (editor, editRef) => {
    ref.current = editRef;
    editor?.target?.on("OpenWindow", (e) => {
      hideShowModal("d-none");
    });
    editor?.target?.on("CloseWindow", (e) => {
      hideShowModal("d-block");
      setLoading(false);
    });
  };

  const handleEditorLabel = (editor, editRef) => {
    labelNameRef.current = editRef;
    editor?.target?.on("OpenWindow", (e) => {
      hideShowModal("d-none");
    });
    editor?.target?.on("CloseWindow", (e) => {
      hideShowModal("d-block");
      setLoading(false);
    });
  };

  const setDataEditor = (url, cb, type) => {
    if (type?.type === "audio/mpeg") {
      var audioHtml = "<audio controls>";
      audioHtml += '<source src="' + url + '" type="' + type.type + '">';
      audioHtml += "Your browser does not support the audio element.";
      audioHtml += "</audio>";
      window.tinymce.activeEditor.insertContent(audioHtml);
      window.tinymce.activeEditor.windowManager.close();
    } else {
      cb(url, {
        title: type.name,
      });
    }
  };

  const handleFileChange = async (e, cb) => {
    const file = e?.[0];
    if (!file) return;
    if (
      file.type === "image/heic" ||
      file.name.toLowerCase().endsWith(".heic")
    ) {
      toast.info(
        "HEIC files are not supported. Please select a different file."
      );
      return;
    }
    if (file.size > 209715200) {
      toast.info("File size should be less than 200MB");
      return;
    }
    setLoading(true);
    const imageTypes = ["image/jpeg", "image/png", "image/webp", "image/gif"];
    const isImage = imageTypes.includes(file.type);
    const data = new FormData();
    if (isImage) {
      data.append("image", file, file.name);
    } else {
      data.append("file", file, file.name);
    }
    try {
      let res;
      if (isImage) {
        res = await dispatch(uploadMediaImage({ filess: data })).unwrap();
      } else {
        res = await dispatch(uploadFile(data)).unwrap();
      }
      if (res.url) {
        setLoading(false);
        setDataEditor(res.url, cb, file);
      } else {
        toast.info("File content too large.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("File upload failed.");
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={12} lg={12}>
          <p
            className={`bold lable C-dark mt-1 itemText`}
            // style={{ display: lable ? "" : "none" }}
          >
            {"Label Name*"}
          </p>
          {loading
            ? toast.loading("Please wait while we process your media...", {
                position: "top-center",
                style: {
                  backgroundColor: "#1471B0",
                  color: "#fff",
                  width: "380px",
                },
              })
            : toast.dismiss()}
          <Editor
            tinymceScriptSrc="/path/to/tinymce.min.js"
            initialValue={labelName}
            onInit={handleEditorLabel}
            init={{
              height: 300,
              menubar: true,
              statusbar: false,
              branding: false,
              resize: true,
              browser_spellcheck: true,
              contextmenu: false,
              plugins:
                "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons fontselect",
              toolbar:
                "undo redo | bold italic underline strikethrough | formatselect | fontselect | fontsizeselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | indent outdent | bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample | ltr rtl | table | code ",
              toolbar_mode: "sliding",
              fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
              forced_root_block: "p",
              newline_behavior: "block",
              autosave_ask_before_unload: false,
              image_title: true,
              file_picker_types: "image",
              file_picker_callback: function (cb, value, meta) {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                  const fileData = this?.files;
                  setTimeout(() => {
                    handleFileChange(fileData, cb);
                  }, 10);
                };
                input.click();
              },
              content_style: "* { margin: 0.02rem; line-height: 1.5; }",
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2 d-flex align-self-center align-items-center">
        <Col xs={12} sm={12} lg={6}>
          <TextInput
            noShadow
            name="placeholder"
            value={placeholder}
            // lable="Place Holder*"
            lable="Place Holder"
            maxlength={190}
            lableClass="itemText"
            onChange={handleChange}
            placeholder="Enter PlaceHolder"
            id={`${id}.textInput.placeHolder`}
          />
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <TextInput
            noShadow
            name="keyName"
            value={keyName}
            lable="Key Name*"
            maxlength={190}
            lableClass="itemText"
            onChange={handleChange}
            placeholder="Enter Key Name"
            id={`${id}.textInput.key`}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} sm={12} lg={6}>
          <DropDown
            noShadow
            lable="Status*"
            data={statusData}
            name="statusField"
            lableClass="itemText"
            placeholder="Choose Status"
            onChange={handleSelectOption}
            value={_.capitalize(statusField)}
            toggleId={`${id}.dropDown.status`}
          />
        </Col>
        {!isInformation && (
          <Col xs={12} sm={12} lg={6} className="mt-3">
            <div className="d-flex justify-content-between">
              <div>
                <span>Required</span>
              </div>
              <div>
                <div xs={2} sm={2} md={2} lg={2} xl={2}>
                  <input
                    id={`switch`}
                    name="required"
                    type="checkbox"
                    value={required}
                    checked={required}
                    onChange={handleChange}
                    className="switch-checkbox"
                  />
                  <button
                    htmlFor={`switch`}
                    className={`switch-label`}
                    onClick={handleFormSwitchBtn}
                    id={`${id}.button.required`}
                  >
                    <span className={`switch-button ${switchBtnColor}`} />
                  </button>
                  <span
                    className="C-818188"
                    style={{ minWidth: "70px" }}
                  ></span>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-2">
        <Col xs={12} sm={12} lg={6}>
          <TextInput
            noShadow
            name="order"
            value={order}
            lable="Order*"
            maxlength={190}
            lableClass="itemText"
            onChange={handleChange}
            placeholder="Enter Order"
            id={`${id}.textInput.order`}
          />
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <DropDown
            noShadow
            // lable="Type*"
            lable={`Type${formElementType === "information" ? "" : "*"}`}
            name="formElementType"
            value={_.capitalize(formElementType?.replace(/_/g, " "))}
            data={formTypeData}
            lableClass="itemText"
            placeholder="Choose Type"
            onChange={handleChoseType}
            toggleId={`${id}.dropDown.type`}
          />
        </Col>
      </Row>
      {!isNewFormModal && isCheckParentValue && (
        <Col xs={12} sm={12} lg={6}>
          <DropDown
            toggleId={`${id}.dropDown.parent`}
            noShadow
            lable="Parent Value*"
            name="parentValue"
            value={_.capitalize(parentValue)}
            data={parentValueData}
            lableClass="itemText"
            placeholder="Select Parent Value"
            onChange={handleChosePrivateValue}
          />
        </Col>
      )}
      {optionsData?.length ||
      columnOptionData?.length ||
      rowOptionData?.length ||
      otherDataValueOptions?.length ||
      otherDisplayValueOptions?.length ? (
        <div className="d-flex justify-content-end F-14 pt-2 cursor-pointer position-relative">
          <span
            id={`${id}.button.clearAll`}
            className="errorMessage position-absolute C-primary text-decoration-underline"
            onClick={onClearAllOptions}
          >
            Clear All
          </span>
        </div>
      ) : (
        ""
      )}
      {isOptionsShow && (
        <Row className="mt-2">
          <div className="d-flex justify-content-between">
            <Col xs={11} sm={11} lg={11}>
              <TextInput
                id={`${id}.${testId}.textInput.addOption`}
                noShadow
                lable="Options*"
                name="options"
                value={options}
                maxlength={100}
                lableClass="itemText"
                placeholder="Add Options"
                onChange={(e) => handleInput(e, "options")}
                onKeyPress={(e) =>
                  handleKeyPress(
                    e,
                    isOptionsShow,
                    "options",
                    "optionsData",
                    "errorMessage"
                  )
                }
              />
              {errorMessage && <p className="errorMessage">{errorMessage}</p>}
            </Col>
            <Col xs={1} sm={1} lg={1} className="form-field-btn mt-4">
              <IoMdAdd
                className="justify-content-center align-items-center cursor-pointer"
                onClick={() =>
                  handleOnSubmitOptions(
                    "options",
                    "optionsData",
                    "errorMessage"
                  )
                }
                id={`${id}.${testId}.button.addOption`}
              />
            </Col>
          </div>
        </Row>
      )}
      {isOptionsShow && optionsData?.length > 0 && (
        <DroppableContext
          id={`${id}.${testId}`}
          type="options"
          stateValue={"optionsData"}
          data={optionsData}
          onPressCancel={handleOnPressCancel}
          handleDragEndData={handleDragEndData}
        />
      )}
      {isMultipleChoiceGrid && (
        <>
          <Row className="mt-2">
            <Col xs={6} sm={6} lg={6}>
              <div className="d-flex justify-content-between">
                <TextInput
                  id={`${id}.${testId}.textInput.addRow`}
                  noShadow
                  lable="Add Row"
                  name="rowOption"
                  value={rowOption}
                  maxlength={500}
                  lableClass="itemText"
                  placeholder="Row"
                  onChange={(e) => handleInput(e, "rowOption")}
                  onKeyPress={(e) =>
                    handleKeyPress(
                      e,
                      isMultipleChoiceGrid,
                      "rowOption",
                      "rowOptionData",
                      "errorMessageRow"
                    )
                  }
                />
                <div className="form-field-btn align-self-center mt-4">
                  <IoMdAdd
                    className="cursor-pointer"
                    onClick={() =>
                      handleOnSubmitOptions(
                        "rowOption",
                        "rowOptionData",
                        "errorMessageRow"
                      )
                    }
                    id={`${id}.${testId}.button.addRow`}
                  />
                </div>
              </div>
              {errorMessageRow && (
                <p className="errorMessage">{errorMessageRow}</p>
              )}
              {rowOptionData?.length > 0 && (
                <DroppableContext
                  id={`${id}.${testId}.row`}
                  type="multiple_choice_grid"
                  stateValue={"rowOptionData"}
                  data={rowOptionData}
                  onPressCancel={handleOnPressCancel}
                  handleDragEndData={handleDragEndData}
                />
              )}
            </Col>
            <Col xs={6} sm={6} lg={6}>
              <div className="d-flex justify-content-between">
                <TextInput
                  id={`${id}.textInput.gridColumn`}
                  noShadow
                  lable="Add Column"
                  name="columnOption"
                  value={columnOption}
                  maxlength={500}
                  lableClass="itemText"
                  placeholder="Column"
                  onChange={(e) => handleInput(e, "columnOption")}
                  onKeyPress={(e) =>
                    handleKeyPress(
                      e,
                      isMultipleChoiceGrid,
                      "columnOption",
                      "columnOptionData",
                      "errorMessageColumn"
                    )
                  }
                />
                <div className="form-field-btn align-self-center mt-4">
                  <IoMdAdd
                    id={`${id}.button.addGridColumn`}
                    className="cursor-pointer"
                    onClick={() =>
                      handleOnSubmitOptions(
                        "columnOption",
                        "columnOptionData",
                        "errorMessageColumn"
                      )
                    }
                  />
                </div>
              </div>
              {errorMessageColumn && (
                <p className="errorMessage">{errorMessageColumn}</p>
              )}
              {columnOptionData?.length > 0 && (
                <DroppableContext
                  id={`${id}.${testId}.column`}
                  type="multiple_choice_grid"
                  stateValue={"columnOptionData"}
                  data={columnOptionData}
                  onPressCancel={handleOnPressCancel}
                  handleDragEndData={handleDragEndData}
                />
              )}
            </Col>
          </Row>
        </>
      )}
      {isLinear && (
        <>
          <Row className="mt-2">
            <Col xs={12} sm={12} lg={6}>
              <DropDown
                toggleId={`${id}.linear.dropDown.minVal`}
                noShadow
                lable="Minimum Value*"
                name="minValue"
                value={_.capitalize(minValue)}
                data={minimumValueData}
                lableClass="itemText"
                placeholder="Select Minimum Value"
                onChange={handleSelectOption}
              />
            </Col>
            <Col xs={12} sm={12} lg={6}>
              <DropDown
                toggleId={`${id}.linear.dropDown.maxVal`}
                noShadow
                lable="Maximum Value*"
                name="maxValue"
                value={_.capitalize(maxValue)}
                data={maximumValueData}
                lableClass="itemText"
                placeholder="Select Maximum Value"
                onChange={handleSelectOption}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} sm={12} lg={6}>
              <TextInput
                id={`${id}.linear.textInput.prefix`}
                noShadow
                name="prefixValue"
                value={prefixValue}
                lable="Prefix*"
                maxlength={190}
                lableClass="itemText"
                onChange={handleChange}
                placeholder="Enter Prefix"
              />
            </Col>
            <Col xs={12} sm={12} lg={6}>
              <TextInput
                id={`${id}.linear.textInput.suffix`}
                noShadow
                name="suffixValue"
                value={suffixValue}
                lable="Suffix*"
                maxlength={190}
                lableClass="itemText"
                onChange={handleChange}
                placeholder="Enter Suffix"
              />
            </Col>
          </Row>
        </>
      )}
      {isScale && (
        <>
          <Row className="mt-2">
            <Col xs={12} sm={12} lg={6}>
              <TextInput
                id={`${id}.scale.textInput.displayVal`}
                noShadow
                lable="Add Display value"
                name="display_value"
                value={display_value}
                maxlength={50}
                lableClass="itemText"
                placeholder="Display value"
                onChange={(e) => handleInput(e, "display_value")}
              />
            </Col>
            <Col xs={12} sm={12} lg={6}>
              <div className="d-flex justify-content-between">
                <div style={{ width: "90%" }} className="pe-2">
                  <TextInput
                    id={`${id}.scale.textInput.dataVal`}
                    noShadow
                    lable="Add Data value"
                    name="data_value"
                    value={data_value}
                    maxlength={50}
                    lableClass="itemText"
                    placeholder="Data value"
                    onChange={(e) => handleInput(e, "data_value")}
                  />
                </div>
                <div className="form-field-btn align-self-center mt-4">
                  <IoMdAdd
                    id={`${id}.scale.button.removeDataVal`}
                    className="cursor-pointer"
                    onClick={handleAddOtherOptions}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {scaleDataError && (
            <p className="text-danger F-14 mt-2">{scaleDataError}</p>
          )}
          {isScale &&
            otherDisplayValueOptions?.length > 0 &&
            otherDataValueOptions?.length > 0 && (
              <DroppableContext
                id={`${id}.scale`}
                type="scale"
                stateValue={"otherDisplayValueOptions"}
                otherDataStateValue={"otherDataValueOptions"}
                data={otherDisplayValueOptions}
                otherDataValueOptions={otherDataValueOptions}
                onPressCancel={(index) => handleClearOtherOption(index)}
                handleDragEndData={handleDragEndData}
              />
            )}
        </>
      )}
      {isInformation && (
        <div className="mt-3">
          {/* {loading
            ? toast.loading("Please wait while we process your media...", {
                position: "top-center",
                style: {
                  backgroundColor: "#1471B0",
                  color: "#fff",
                  width: "380px",
                },
              })
            : toast.dismiss()} */}
          <Editor
            tinymceScriptSrc="/path/to/tinymce.min.js"
            initialValue={content}
            onInit={handleEditorInit}
            init={{
              height: 300,
              menubar: true,
              statusbar: false,
              branding: false,
              resize: true,
              browser_spellcheck: true,
              contextmenu: false,
              plugins:
                "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons fontselect",
              toolbar:
                "undo redo | bold italic underline strikethrough | formatselect | fontselect | fontsizeselect | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | indent outdent | bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample | ltr rtl | table | code ",
              toolbar_mode: "sliding",
              fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
              forced_root_block: "p",
              newline_behavior: "block",
              autosave_ask_before_unload: false,
              image_title: true,
              file_picker_types: "image",
              file_picker_callback: function (cb, value, meta) {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                  const fileData = this?.files;
                  setTimeout(() => {
                    handleFileChange(fileData, cb);
                  }, 10);
                };
                input.click();
              },
              content_style: "* { margin: 0.02rem; line-height: 1.5; }",
            }}
          />
        </div>
      )}
    </>
  );
});

export default AddFormField;
