import { lazy } from "react";

// Schoolhealth component import
const Pages = lazy(() => import("@components/Pages"));
const NotFound = lazy(() => import("@components/NotFound"));
const SocialCallback = lazy(() => import("@components/SocialCallback"));
const Login = lazy(() => import("@pages/auth/Login"));
const WelnetLogin = lazy(() => import("@pages/auth/WelnetLogin"));
const VerifyEmail = lazy(() => import("@pages/auth/VerifyEmail"));
const ForgotPassword = lazy(() => import("@pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("@pages/auth/ResetPassword"));
const Dashboard = lazy(() => import("@pages/dashboard"));
const Locations = lazy(() => import("@pages/locations"));
const People = lazy(() => import("@pages/people"));
const Equipment = lazy(() => import("@pages/equipment"));
const Reports = lazy(() => import("@pages/reports"));
const LocationDetail = lazy(() => import("@pages/locations/locationDetails"));
const EquipmentDetail = lazy(() => import("@pages/equipment/equipmentDetail"));
const PeopleDetail = lazy(() => import("@pages/people/peopleDetails"));
const ReportDetail = lazy(() => import("@pages/reports/reportDetail"));
const EquipmentRegistration = lazy(() =>
  import("@pages/EquipmentRegistration")
);
const EditProfile = lazy(() => import("@pages/profile/EditProfile"));
const ChangePassword = lazy(() => import("@pages/profile/ChangePassword"));
const LicenseInfo = lazy(() => import("@pages/profile/LicenseInfo"));
const LicenseHistory = lazy(() => import("@pages/profile/LicenseHistory"));
const PrintReport = lazy(() =>
  import("@pages/reports/reportDetail/PrintReport")
);
const LawCenter = lazy(() => import("@pages/lawCenter"));
const Medications = lazy(() => import("@pages/Medications"));
const MedicationDetail = lazy(() =>
  import("@pages/Medications/medicationDetail")
);
const ProfileInfo = lazy(() => import("@pages/profile/ProfileInfo"));
const Preference = lazy(() => import("@pages/profile/Preference"));
const Credential = lazy(() => import("@pages/Credential"));
const Courses = lazy(() => import("@pages/Courses"));
const CourseDetail = lazy(() => import("@pages//Courses/courseDetail"));
const Form = lazy(() => import("@pages/FormMenu/index"));
const AddForm = lazy(() => import("@pages/FormMenu/widgets/addForm"));
const FormDetail = lazy(() => import("@pages/FormMenu/widgets/formDetail"));
// curriculum component
const CurrDashboard = lazy(() =>
  import("@pages/curriculum/dashboard/CurrDashboard")
);
const Curriculum = lazy(() => import("@/pages/curriculum/versions"));

export {
  Pages,
  NotFound,
  SocialCallback,
  Login,
  Dashboard,
  LawCenter,
  LicenseHistory,
  LicenseInfo,
  LocationDetail,
  Locations,
  MedicationDetail,
  Medications,
  People,
  PeopleDetail,
  Preference,
  PrintReport,
  ProfileInfo,
  VerifyEmail,
  EditProfile,
  ForgotPassword,
  ResetPassword,
  Equipment,
  ReportDetail,
  Reports,
  EquipmentDetail,
  EquipmentRegistration,
  ChangePassword,
  Credential,
  CourseDetail,
  Courses,
  WelnetLogin,
  Form,
  AddForm,
  FormDetail,
  // curriculum
  CurrDashboard,
  Curriculum,
};
