export const API_URL =
  process.env.REACT_APP_API_URL ?? "http://localhost:8000/api/";
export const SOCIAL_URL =
  process.env.REACT_APP_SOCIAL_URL ?? "http://localhost:8000/";
export const BASE_NAME = process.env.REACT_APP_BASE_NAME;
export const UPS_URL =
  "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=";

export const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const APP_ID = process.env.REACT_APP_APP_ID;
export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;
export const SENDER_ID = process.env.REACT_APP_SENDER_ID;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;

export const ACCESSORIES_WARNING_DAYS = 60;

export const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_SECRET = process.env.REACT_APP_PUSHER_APP_SECRET;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;
export const PUSHER_USE_TLS = process.env.REACT_APP_PUSHER_USE_TLS;
export const PUSHER_LOGS = process.env.REACT_APP_PUSHER_LOGS;

export const AED_USE_REPORT_SLUG = "aed-use-report";
