import { isNotUser, isSuperAdmin } from "@/helpers";
import {
  getAvailableInstructorList,
  getAvailableStudentList,
} from "@/services";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  getAvailablePeople,
  getPeopleDetail,
  getUserRoleList,
  getUsersList,
  getUserTypes,
} from "../services/people.service";

const initialState = {
  usersList: [],
  filteredUsersList: [],
  unfilteredUsersList: [],
  selectedUsersList: [],
  userRoleList: null,
  allRoleList: [],
  availablePeopleList: null,
};

export const people = createSlice({
  name: "people",
  initialState,
  reducers: {
    resetFilteredUsersList: (state, action) => {
      state.filteredUsersList = [];
    },
    selectedUsers: (state, action) => {
      state.selectedUsersList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.fulfilled, (state, action) => {
        const { data = [], total = 0 } = action.payload || {};
        const { offset = 0, isFilter } = action.meta.arg || {};
        const filterActiveUsers = data?.filter(
          (item) => item?.status === "active"
        );
        state.usersList = data;
        state.filteredListData = null;
        state.totalUser = total;
        if (isFilter) {
          state.filteredUsersList =
            offset === 0
              ? filterActiveUsers
              : state.filteredUsersList.concat(filterActiveUsers);
        } else {
          state.unfilteredUsersList =
            offset === 0
              ? filterActiveUsers
              : state.unfilteredUsersList.concat(filterActiveUsers);
        }
      })
      .addCase(getAvailablePeople.fulfilled, (state, action) => {
        state.availablePeopleList = action?.payload?.data;
      })
      .addCase(getUserRoleList.fulfilled, (state, action = {}) => {
        let role;
        const { payload } = action;
        if (payload && payload?.data) {
          role = isSuperAdmin()
            ? payload?.data && payload?.data?.length
              ? payload?.data
              : []
            : !isNotUser()
            ? payload?.data?.filter((role) => role?.level > 3)
            : payload?.data?.filter((role) => role.level > 2);
          role = _.sortBy(role, (role) => role.level);
        }
        return {
          ...state,
          allRoleList: _.sortBy(payload?.data, (role) => role.level),
          userRoleList: role,
        };
      })
      .addCase(getUserTypes.fulfilled, (state, action) => {
        state.userTypes = action?.payload?.data;
      })
      .addCase(getAvailableStudentList.fulfilled, (state, action) => {
        state.availablePeopleList = action?.payload?.data;
      })
      .addCase(getAvailableInstructorList.fulfilled, (state, action) => {
        state.availablePeopleList = action?.payload?.data;
      })
      .addCase(getPeopleDetail.rejected, (state, action) => {
        // Handle rejection if necessary
      });
  },
});

export const { resetFilteredUsersList, selectedUsers } = people.actions;
export default people.reducer;
