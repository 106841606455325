import { Dropdown, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "@images/logo.png";
import logoSH from "@images/logoSH.png";
import logoCurriculum from "@images/logoCurriculum.png";
import { useDispatch, useSelector } from "react-redux";
import curriculumLogo from "../../../assets/images/curriculumLogo.png";
import complianceLogo from "@/assets/images/Complaince.png";
import curriculum from "@/assets/images/Curriculum.png";
import welnetIcon from "@/assets/images/welnetI-icon.png";
import placeHolderImage from "@/assets/images/placeHolderImage.jpeg";
import { useEffect, useState } from "react";
import { isSuperAdmin, toggleNav } from "@/helpers";
import { FaHome } from "react-icons/fa";
import { changeWebApp } from "@/reducers/auth";
const LogoContainer = ({ toggleSideNav, isSearch, changeWeb, webApp }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reducerData = useSelector((state) => {
    return {
      selectedVersion: state.curriculum.curriculumDetails,
      parentCategories: state?.equipment?.parentCategories,
    };
  });
  // const currentWebApp = useSelector((state) => state.auth.webApp);
  const currentPath = window.location.pathname;

  const currentURL = new URL(window.location.href);
  const getVersionId = currentURL.searchParams.get("versionId");

  const { selectedVersion, parentCategories } = reducerData;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const testId = currentPath?.includes("curriculum") ? "curriculum" : "compliance";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = async () => {
    if (currentPath?.includes("curriculum")) {
      // await localStorage.setItem("isCurriculum", "schoolhealth");
      // setTimeout(async () => {
      //   await dispatch(changeWebApp('schoolhealth'));
      //   await navigate('/dashboard');
      //   window.location.reload();
      // }, 0)
      navigate("/curriculum");
    } else {
      navigate("/dashboard");
    }
  };

  const navigateToDashboard = () => {
    if (
      (getVersionId !== null && getVersionId?.length) ||
      [
        "/curriculum/profile",
        "/curriculum/profile/edit",
        "/curriculum/change-password",
        "/curriculum/setting",
      ].includes(currentPath)
    ) {
      navigate("/curriculum");
    } else {
      setTimeout(() => {
        navigate("/dashboard");
        window.location.reload();
      }, 0);
    }
  };

  return (
    <div className="align-items-center d-flex h-100 logo-container">
      <div className="h-100 d-flex flex-row align-items-center justify-content-between ps-md-0">
        <label
          className="web-btn ms-0 ms-md-2"
          role={"button"}
          onClick={() =>
            currentPath?.includes("curriculum")
              ? navigateToDashboard()
              : navigate("/dashboard")
          }
          id={`header.${testId}.home.link`}
        >
          <FaHome
            size={28}
            color={currentPath?.includes("curriculum") ? "#1471b0" : "#c60970"}
          />
        </label>
        <div
          onClick={handleNavigate}
          className="h-100 center cursor-pointer"
          id={`header.${testId}.logo.link`}
        >
          {currentPath.includes("curriculum") &&
          [
            "/curriculum",
            "/curriculum/profile",
            "/curriculum/change-password",
            "/curriculum/setting",
            "/curriculum/profile/edit",
          ]?.includes(location?.pathname) ? (
            <>
              <Image
                alt="logo"
                src={logoCurriculum}
                className="displayNone logoSH"
              />
              <Image
                alt="logo"
                src={curriculumLogo}
                className="dashLogo curriculum-logo m-0"
              />
            </>
          ) : currentPath.includes("curriculum") &&
            ![
              "/curriculum",
              "/curriculum/profile",
              "/curriculum/change-password",
              "/curriculum/setting",
              "/curriculum/profile/edit",
            ]?.includes(location?.pathname) ? (
            <>
              <Image
                src={
                  selectedVersion?.image
                    ? selectedVersion?.image
                    : placeHolderImage
                }
                className="curriculum-selected-logo"
              />
            </>
          ) : (
            <>
              <Image alt="logo" src={logoSH} className="displayNone logoSH" />
              <Image alt="logo" src={logo} className="dashLogo m-0" />
            </>
          )}
        </div>
      </div>
      {currentPath.includes("curriculum") &&
      ![
        "/curriculum",
        "/curriculum/profile",
        "/curriculum/change-password",
        "/curriculum/setting",
        "/curriculum/profile/edit",
      ]?.includes(location?.pathname) ? (
        <div
          className="ms-3 curr_name cursor-pointer"
          onClick={() =>
            currentPath.includes("schoolhealth") || currentPath === undefined
              ? navigate("/dashboard")
              : navigate("/curriculum")
          }
          title={selectedVersion?.name}
        >
          {selectedVersion?.name?.length > 20
            ? selectedVersion?.name?.slice(0, 19) + "..."
            : selectedVersion?.name}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LogoContainer;
