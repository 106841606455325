import store from "../store";
import { refreshToken, updateDeviceToken } from "../services";
import { getLoggedInUserToken } from "./auth";
import { expiredTokenModal } from "@/reducers/auth";
// import moment from "moment";

export const refreshTokenActionDispatcher = () => {
  const refresh_token = getLoggedInUserToken()?.refreshToken;
  if (refresh_token) {
    store.dispatch(refreshToken({ refresh_token }));
  }
};

export const expiredTokenModalActionDispatcher = () => {
  store.dispatch(expiredTokenModal());
};

export const updateDeviceUpsertToken = (data) => {
  store.dispatch(updateDeviceToken(data));
};
