import GlobalSearch from "@pages/globalSearch";
import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Topbar from "@/components/Layout/Topbar/Topbar";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import {
  NotFound,
  SocialCallback,
  Login,
  Dashboard,
  LawCenter,
  LicenseHistory,
  LicenseInfo,
  LocationDetail,
  Locations,
  MedicationDetail,
  Medications,
  People,
  PeopleDetail,
  Preference,
  PrintReport,
  ProfileInfo,
  Pages,
  VerifyEmail,
  EditProfile,
  ForgotPassword,
  ResetPassword,
  Equipment,
  ReportDetail,
  Reports,
  EquipmentDetail,
  EquipmentRegistration,
  ChangePassword,
  Credential,
  CourseDetail,
  Courses,
  CurrDashboard,
  FiveForLife,
  Curriculum,
  WelnetLogin,
  Form,
  FormDetail,
} from "./routesImport";
import Page from "@/components/Page";
import Forms from "@/pages/Forms";
import FormSubmitIndex from "@/pages/Forms/formSubmitIndex";
import AddForm from "@/pages/FormMenu/widgets/addForm";

const toggleSideNav = (e) => {
  let el = document.getElementById("sideNav");
  e?.target.checked
    ? el.classList.remove("sideNav", "isSearchNav")
    : el.classList.add("sideNav", `${"isSearchNav"}`);
};

const parentCategories = localStorage.getItem("permissions");
const parseParentCategories = JSON.parse(parentCategories);
const location = window.location.pathname;

// routes for both website(schoolhealth, curriculum)
const commonRoute = [
  {
    path: "/",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
    errorElement: (
      <Suspense>
        <NotFound />
      </Suspense>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/welnet-login",
    element: (
      <PublicRoute>
        <WelnetLogin />
      </PublicRoute>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/reset-password",
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/verify-email",
    element: (
      <PublicRoute>
        <VerifyEmail />
      </PublicRoute>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/social/callback",
    element: (
      <PublicRoute>
        <SocialCallback />
      </PublicRoute>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/pages/:slug",
    element: <Page toggleSideNav={toggleSideNav} />,
  },
  {
    path: "/forms/:slug",
    element: <Forms toggleSideNav={toggleSideNav} />,
  },
  {
    path: "/forms/submit-form",
    element: <FormSubmitIndex toggleSideNav={toggleSideNav} />,
  },
];

//router for schoolhealth
export const router = createBrowserRouter([
  ...commonRoute,
  {
    path: "/",
    element: (location?.includes("/curriculum")
      ? parseParentCategories?.includes("curriculum")
      : true) && <Topbar toggleSideNav={toggleSideNav} />,
    errorElement: (
      <Suspense>
        <NotFound />
      </Suspense>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "locations",
        element: (
          <ProtectedRoute>
            <Locations />
          </ProtectedRoute>
        ),
      },
      {
        path: "locations/:locationId",
        element: (
          <ProtectedRoute>
            <LocationDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "people",
        element: (
          <ProtectedRoute>
            <People />
          </ProtectedRoute>
        ),
      },
      {
        path: "people/:peopleId",
        element: (
          <ProtectedRoute>
            <PeopleDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "equipment",
        element: (
          <ProtectedRoute>
            <Equipment />
          </ProtectedRoute>
        ),
      },
      {
        path: "equipment/:equipmentId",
        element: (
          <ProtectedRoute>
            <EquipmentDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "equipment/registration",
        element: (
          <ProtectedRoute>
            <EquipmentRegistration />
          </ProtectedRoute>
        ),
      },
      {
        path: "credentials",
        element: (
          <ProtectedRoute>
            <Credential />
          </ProtectedRoute>
        ),
      },
      {
        path: "courses",
        element: (
          <ProtectedRoute>
            <Courses />
          </ProtectedRoute>
        ),
      },
      {
        path: "courses/:courseId",
        element: (
          <ProtectedRoute>
            <CourseDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "medication",
        element: (
          <ProtectedRoute>
            <Medications />
          </ProtectedRoute>
        ),
      },
      {
        path: "medication/:medicationId",
        element: (
          <ProtectedRoute>
            <MedicationDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "medication/registration",
        element: (
          <ProtectedRoute>
            <EquipmentRegistration />
          </ProtectedRoute>
        ),
      },
      {
        path: "reports",
        element: (
          <ProtectedRoute>
            <Reports />
          </ProtectedRoute>
        ),
      },
      {
        path: "law-center",
        element: (
          <ProtectedRoute>
            <LawCenter />
          </ProtectedRoute>
        ),
      },
      {
        path: "reports/:reportId",
        element: (
          <ProtectedRoute isSearch={true}>
            <ReportDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <ProfileInfo />
          </ProtectedRoute>
        ),
      },
      {
        path: "curriculum/profile",
        element: (
          <ProtectedRoute isSearch={true}>
            <ProfileInfo />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile/edit",
        element: (
          <ProtectedRoute>
            <EditProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "curriculum/profile/edit",
        element: (
          <ProtectedRoute isSearch={true}>
            <EditProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile/change-password",
        element: (
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "curriculum/change-password",
        element: (
          <ProtectedRoute isSearch={true}>
            <ChangePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile/setting",
        element: (
          <ProtectedRoute>
            <Preference />
          </ProtectedRoute>
        ),
      },
      {
        path: "curriculum/setting",
        element: (
          <ProtectedRoute isSearch={true}>
            <Preference />
          </ProtectedRoute>
        ),
      },
      {
        path: "license-info",
        element: (
          <ProtectedRoute>
            <LicenseInfo />
          </ProtectedRoute>
        ),
      },
      {
        path: "license-history",
        element: (
          <ProtectedRoute>
            <LicenseHistory />
          </ProtectedRoute>
        ),
      },
      {
        path: "search",
        element: (
          <ProtectedRoute isSearch={true}>
            <GlobalSearch />
          </ProtectedRoute>
        ),
      },
      {
        path: "curriculum",
        element:
          parseParentCategories?.length &&
          parseParentCategories?.includes("curriculum") ? (
            <ProtectedRoute isSearch={true}>
              <CurrDashboard />
            </ProtectedRoute>
          ) : (
            <NotFound />
          ),
      },
      {
        path: "curriculum/:id",
        element:
          parseParentCategories?.length &&
          parseParentCategories?.includes("curriculum") ? (
            <ProtectedRoute>
              <Curriculum />
            </ProtectedRoute>
          ) : (
            <NotFound />
          ),
      },
      {
        path: "/forms",
        element: (
          <ProtectedRoute>
            <Form />
          </ProtectedRoute>
        ),
      },
      {
        path: "/forms/add-form",
        element: (
          <ProtectedRoute>
            <AddForm />
          </ProtectedRoute>
        ),
      },
      {
        path: "/forms/form-detail",
        element: (
          <ProtectedRoute>
            <FormDetail />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "report-print",
    element: <PrintReport />,
  },
  {
    path: "dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
]);
