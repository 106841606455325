import closeIcon from "@images/closeButton.png";

const CloseButton = ({ onClose, curriculum, id }) => {
  return (
    <button
      className={`rounded-circle border-0 center Bg-primary C-fff p-0 m-0 ${
        curriculum ? "Bg-curriculum" : "Bg-primary"
      }`}
      style={{ height: "22px", minWidth: "22px", width: "22px" }}
      onClick={onClose}
      id={id}
    >
      <img src={closeIcon} alt="Close" className="w-100 h-100" />
    </button>
  );
};

export default CloseButton;
