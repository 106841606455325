import {
  addMultiStudent,
  addServiceOrg,
  deleteStudent,
  getCourseDetail,
  getCourseList,
  getStateList,
  updateCourseInfo,
  updateStudent,
} from "@/services";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseList: [],
  stateList: [],
  courseDetail: {},
};

const course = createSlice({
  name: "course",
  initialState,
  reducers: {
    updateCourseDetail: (state, action) => {
      state.courseDetail = action.payload.course;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseList.fulfilled, (state, action) => {
        state.courseList = action?.payload.data;
      })
      .addCase(getStateList.fulfilled, (state, action) => {
        state.stateList = action?.payload?.data;
      })
      .addCase(getCourseDetail.fulfilled, (state, action) => {
        state.courseDetail = action?.payload?.data;
      })
      .addCase(addMultiStudent.fulfilled, (state, action) => {
        state.courseDetail.attendees += action?.meta?.arg?.user_ids?.length;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === "rejected") {
          state.courseDetail.attendees -= 1;
        } else if (action?.payload?.data?.status === "approved") {
          state.courseDetail.attendees += 1;
        }
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        if (action?.meta?.arg?.status === "approved") {
          state.courseDetail.attendees -= 1;
        }
      })
      .addCase(addServiceOrg.fulfilled, (state, action) => {
        state.courseDetail = {
          ...state.courseDetail,
          service_account: {
            account: { name: action?.meta?.arg?.organization },
          },
        };
      })
      .addCase(updateCourseInfo.fulfilled, (state, action) => {
        state.courseDetail = {
          ...state.courseDetail,
          course_info: action?.meta?.arg,
        };
      });
  },
});

export const { updateCourseDetail } = course.actions;
export default course.reducer;
