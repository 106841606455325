import React from "react";
import PublicRoute from "@/routes/PublicRoute";
import { isUserAuthenticated } from "@/helpers";
import ProtectedRoute from "@/routes/ProtectedRoute";
import Topbar from "@/components/Layout/Topbar/Topbar";
import FormSubmit from "./widgets/formSubmit";

const FormSubmitIndex = ({ toggleSideNav }) => {
  return (
    <>
      {isUserAuthenticated() ? (
        <>
          <Topbar toggleSideNav={toggleSideNav} />
          <ProtectedRoute>
            <FormSubmit />
          </ProtectedRoute>
        </>
      ) : (
        <>
          <PublicRoute>
            <FormSubmit />
          </PublicRoute>
        </>
      )}
    </>
  );
};

export default FormSubmitIndex;
