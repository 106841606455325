import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Banner from "../pages/auth/Banner";
import Logo from "../pages/auth/Logo";

import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/auth/index.css";
import { setUserToken } from "@/helpers";
import { useDispatch } from "react-redux";
import {
  getEquipmentCategoriesList,
  welnetLogin,
  welnetToken,
} from "@/services";

const SocialCallback = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(location.search);
  const user = JSON.parse(params.get("user"));
  const accessToken = params.get("accessToken");
  const expiresAt = params.get("expiresAt");
  const getMessage = params.get("message");
  const redirectURL = params.get("redirect_uri");
  const refreshToken = params.get("refreshToken");

  function removeQueryString(url) {
    const urlObj = new URL(url);

    urlObj.search = "";
    return urlObj.toString();
  }
  const handleUpdateLogin = () => {
    setUserToken({ accessToken, expiresAt, user, refreshToken });
    window.location.reload();
    navigate("/dashboard");
    setIsLoading(false);
  };

  const clearLocalStorageAndNavigate = (url) => {
    localStorage.clear();
    window.location.assign(url);
  };

  const handleWelnetAuthentication = async () => {
    dispatch(getEquipmentCategoriesList()).then(async (res) => {
      const parentCategoryList = res?.payload?.data?.filter(
        (item) => !item?.parent_id
      );
      const parentCategories = parentCategoryList?.map(
        (category) => category?.slug
      );
      let isCheckWelnet = parentCategories?.includes("welnet");
      if (parentCategories?.length === 1 && isCheckWelnet) {
        const emailObj = {
          email: user?.email,
        };
        dispatch(welnetToken({ data: emailObj })).then((res) => {
          if (res?.payload?.access_token) {
            const welnetToken = {
              accessToken: res?.payload?.access_token,
            };
            dispatch(welnetLogin({ data: welnetToken })).then((res) => {
              if (res?.payload?.redirect_uri) {
                clearLocalStorageAndNavigate(res?.payload?.redirect_uri);
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
            });
          } else {
            setIsLoading(false);
          }
        });
      } else {
        handleUpdateLogin();
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    let isUser = user && user?.user_role?.slug === "user";
    if (accessToken && user && user.id && redirectURL) {
      setUserToken({ accessToken, expiresAt, user, refreshToken });
      navigate(`${redirectURL}`);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
      setIsLoading(false);
    } else if (accessToken && user && user.id && isUser) {
      setUserToken({ accessToken, expiresAt, user, refreshToken });
      handleWelnetAuthentication();
    } else if (accessToken && user && user.id) {
      handleUpdateLogin();
    } else {
      toast.error(getMessage || "Invalid Social Login attempt!");
      navigate("/");
      setIsLoading(false);
    }
  }, [accessToken, expiresAt, user, redirectURL, navigate, getMessage]);

  return isLoading ? (
    <p>{"Loading..."}</p>
  ) : (
    <div className="App">
      <Row className="p-0 m-0">
        <Banner />
      </Row>
    </div>
  );
};

export default SocialCallback;
