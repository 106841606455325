import { isEmail } from "validator";
import { isValidMobile } from ".";
import moment from "moment";
import { toast } from "react-toastify";

export const required = (value) => {
  if (!value) {
    return (
      <div className="validation-alert" role="alert">
        <p style={{ color: "#f00", fontSize: "13px" }}>
          This field is required!
        </p>
      </div>
    );
  }
};

export const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="validation-alert" role="alert">
        <p style={{ color: "#f00", fontSize: "13px" }}>Invalid email.</p>
      </div>
    );
  }
};

export const mobile = (value) => {
  if (!isValidMobile(value)) {
    return (
      <div className="validation-alert" role="alert">
        <p style={{ color: "#f00", fontSize: "13px" }}>Invalid phone number.</p>
      </div>
    );
  }
};

export const password = (value) => {
  if (value.length < 8 || value.length > 20) {
    return (
      <div className="validation-alert" role="alert">
        <p style={{ color: "#f00", fontSize: "13px" }}>
          The password must have 8 - 20 characters.
        </p>
      </div>
    );
  }
};

export const confirmPassword = (value, props, components) => {
  // NOTE: Tricky place. The 'value' argument is always current component's value.
  // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
  // But if we're changing 'confirm' component - the condition will always be true
  // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.

  if (components["password"][0].value !== components["cpassword"][0].value) {
    // value !== components['cpassword'][0].value
    // 'confirm' - name of input
    // components['confirm'] - array of same-name components because of checkboxes and radios
    // return <span className="error">Passwords are not equal.</span>
    return (
      <div className="validation-alert" role="alert">
        <p style={{ color: "#f00", fontSize: "13px" }}>
          Passwords does not match.
        </p>
      </div>
    );
  }
};

export const newValidPass = (value, props, components) => {
  var pass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  if (!pass.test(components["password"][0].value)) {
    return (
      <div
        className="validation-alert"
        role="alert"
        style={{ maxWidth: "70%" }}
      >
        <p style={{ color: "#f00", fontSize: "13px" }}>
          Your password must be have at least 8 characters long and contains one
          uppercase ,one lowercase character ,one numeric and a special
          character.
        </p>
      </div>
    );
  }
};

export const isNewValidPass = (value) => {
  var pass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

  if (pass.test(value) && value.length >= 8) {
    return true;
  }

  return false;
};

export const validateDateFormat = (dateString) => {
  const formatString = "YYYY/MM/DD";
  const parsedDate = moment(dateString, formatString, true);
  return parsedDate.isValid();
};

export const showErrorAlerts = (errorData) => {
  const errorMessages = [];

  for (const key in errorData) {
    if (errorData[key] && Array.isArray(errorData[key])) {
      errorMessages.push(...errorData[key]);
    }
  }

  errorMessages.forEach((message, index) => {
    setTimeout(() => {
      toast.warn(message || 'Error');
    }, index * 500);
  });
};